import React from "react";
import ListGroupOrderItem from "./ListGroupOrderItem";
import ListGroupOrderItemMobile from "./ListGroupOrderItemMobile";
import { useTranslation } from "react-i18next";

export default function ListGroupOrder({ orders }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="sm:hidden">
        <ul className="mt-3 divide-y-2 divide-cal-primary-floral-white-FEF9EF">
          {orders.map((order) => (
            <ListGroupOrderItemMobile key={order.id} order={order} />
          ))}
        </ul>
      </div>

      <div className="hidden sm:block">
        <div className="inline-block min-w-full align-middle">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="py-4 font-normal tracking-wider text-left opacity-70 text-cal-primary-gunmetal-black-202833 ">
                  {t("Bezeichnung")}
                </th>
                <th className="px-6 py-4 font-normal tracking-wider text-left opacity-70 text-cal-primary-gunmetal-black-202833 ">
                  {t("Schulcode")}
                </th>
                <th className="px-6 py-4 font-normal tracking-wider text-left opacity-70 text-cal-primary-gunmetal-black-202833 ">
                  {t("Bestellfrist")}
                </th>
                <th className="px-6 py-4 font-normal tracking-wider text-left opacity-70 text-cal-primary-gunmetal-black-202833 ">
                  {t("Status")}
                </th>
                <th className="hidden px-6 py-4 font-normal tracking-wider text-left opacity-70 text-cal-primary-gunmetal-black-202833 lg:table-cell ">
                  {t("Bestellungen")}
                </th>
                <th className="hidden py-4 pr-6 font-normal tracking-wider text-center opacity-70 text-cal-primary-gunmetal-black-202833 lg:table-cell "></th>
                <th className="py-4 pr-6 font-normal tracking-wider text-right opacity-70 text-cal-primary-gunmetal-black-202833 "></th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-cal-primary-floral-white-FEF9EF ">
              {(orders || []).map((order) => (
                <ListGroupOrderItem key={order.id} order={order} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
