import React, { PureComponent } from "react";

export default class Loading extends PureComponent {
  render() {
    return (
      <div className="fixed top-0 left-0 z-50 block w-full h-full bg-gray-200 opacity-75">
        <span className="relative block w-0 h-0 mx-auto my-0 text-teal-600 opacity-75 top-1/2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 animate-spin"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
        </span>
      </div>
    );
  }
}
