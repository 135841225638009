import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import HomeScreen from "../screens/HomeScreen";
import LoginScreen from "../screens/LoginScreen";
import AuthenticationScreen from "../screens/AuthenticationScreen";
import CreateGroupOrderScreen from "../screens/CreateGroupOrderScreen";

import PrivateRoute from "./PrivateRoute";
import GroupOrderScreen from "../screens/GroupOrderScreen";
import OrderOverviewScreen from "../screens/OrderOverviewScreen";
import {ErrorBoundary} from "@sentry/react";

const screens = [
  {
    path: "/authentication",
    isPublicRoute: true,
    component: AuthenticationScreen,
  },
  { path: "/sign-in", isPublicRoute: true, component: LoginScreen },
  { path: "/create", component: CreateGroupOrderScreen },
  { path: "/group-orders/:id/overview", component: OrderOverviewScreen },
  { path: "/group-orders/:id/status", component: OrderOverviewScreen },
  { path: "/group-orders/:id", component: GroupOrderScreen },
  { path: "/", component: HomeScreen },
];

export default function Routes() {
    return (
        <Switch>
            {screens.map((screen) => {
                if (screen.isPublicRoute) {
                    const PublicPage = screen.component;
                    return (
                        <Route key={screen.path} path={screen.path}>
                            <PublicPage />
                        </Route>
                    );
                } else {
                    const ProtectedPage = screen.component;
                    return (
                        <PrivateRoute key={screen.path} path={screen.path}>
                            <ErrorBoundary fallback={({error, componentStack, resetError}) => (<Redirect to={'/'}/>)}>
                                <ProtectedPage/>
                            </ErrorBoundary>
                        </PrivateRoute>
                    );
                }
            })}
        </Switch>
    );
}
