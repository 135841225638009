import * as React from "react";

function BackIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 13.185 31.417"
    >
      <path
        id="Path_247"
        data-name="Path 247"
        d="M28.621,0,14.311,10.545,0,0"
        transform="translate(11.787 1.398) rotate(90)"
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
}

export default BackIcon;
