import React from "react";
import ImageOrderItem from "./ImageOrderItem";

export default function ImagesOrderItem({ images }) {
  const limit = 4;
  let items = [];
  let span = null;

  if (images.length > limit) {
    for (let index = 0; index < limit; index++) {
      const image = images[index];
      items.push(<ImageOrderItem key={index} src={image} alt={image} />);
    }
    span = (
      <span className="flex-shrink-0 text-xs font-medium leading-5">
        +{images.length - limit}
      </span>
    );
  } else {
    items = images.map((product_image, key) => (
      <ImageOrderItem key={key} src={product_image} alt={product_image} />
    ));
  }

  return (
    <>
      <div className="flex flex-shrink-0 -space-x-1">{items}</div>
      {span}
    </>
  );
}
