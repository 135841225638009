import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import React from "react";
import Slider, { SliderTooltip } from "rc-slider";

const { Handle } = Slider;

const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
      <SliderTooltip
          prefixCls="rc-slider-tooltip"
          overlay={value}
          visible={dragging}
          placement="top"
          key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
  );
};

export default function NumStudentSlider(props) {
  const marks = () => {
    let result = {};
    for (let idx = 0; idx <= 400; idx = idx + 80) {
      result[idx] = idx;
    }
    return result;
  };
  return (
      <Slider
          dots={true}
          step={20}
          max={400}
          marks={marks()}
          handle={handle}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          handleStyle={{
            height: 24,
            width: 24,
            marginTop: -12,
            marginLeft: 3
          }}
          dotStyle={{
            height: 12,
            width: 12,
            marginBottom: -2
          }}
      />
  );
};