import classNames from "classnames";
import React from "react";

export default function CategoryItem({
  children,
  image,
  onClick,
  onClickRemmove,
  id,
  active,
}) {
  return (
    <div
      className={classNames(
        "inline-flex items-center justify-between px-4 py-1 space-x-2 text-xs border-2 rounded-full md:text-sm ",
        {
          " border-cal-secondary-fiery-rose-f74e6c bg-cal-secondary-fiery-rose-f74e6c text-cal-primary-floral-white-FEFCF6 font-medium": active,
          " border-cal-primary-floral-white-E0DACC bg-cal-primary-floral-white-FFFEFC text-cal-primary-gunmetal-black-202833": !active,
        }
      )}
    >
      {image ? (
        <img
          className="inline-block w-5 h-5 rounded-full md:w-8 md:h-8"
          src={image}
          alt=""
        ></img>
      ) : (
        ""
      )}
      <span
        className="w-full my-1 truncate cursor-pointer"
        onClick={() => {
          if (active) {
            onClickRemmove(id);
          } else {
            onClick(id);
          }
        }}
      >
        {children}
      </span>

      <button
        type="button"
        onClick={() => {
          if (active) {
            onClickRemmove(id);
          } else {
            onClick(id);
          }
        }}
        className={classNames(
          "inline-flex items-center justify-center flex-shrink-0 w-6 h-6 rounded-full  focus:outline-none ",
          {
            " text-cal-primary-floral-white-FEFCF6 font-medium": active,
            "  text-cal-primary-floral-white-E0DACC": !active,
          }
        )}
      >
        <svg
          className="w-2 h-2"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 8 8"
        >
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </div>
  );
}
