import * as React from "react";

function MenuIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={27}
      height={6}
      viewBox="0 0 27 6"
      {...props}
    >
      <g data-name="Group 774" transform="translate(-1140 -386)" fill="#01a7a3">
        <circle
          data-name="Ellipse 79"
          cx={3}
          cy={3}
          r={3}
          transform="rotate(-90 766 -374)"
        />
        <circle
          data-name="Ellipse 80"
          cx={3}
          cy={3}
          r={3}
          transform="rotate(-90 771 -379)"
        />
        <circle
          data-name="Ellipse 81"
          cx={3}
          cy={3}
          r={3}
          transform="rotate(-90 776.5 -384.5)"
        />
      </g>
    </svg>
  );
}

export default MenuIcon;
