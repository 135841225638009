import React from "react";
import { Menu } from "@headlessui/react";
import GroupOrderMenus from "../../components/GroupOrderMenus";
import * as dayjs from "dayjs";
import Status from "../../components/Status";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IN_REVIEW } from "../../constants/status";

export default function ListGroupOrderItemMobile({ order }) {
  const { t } = useTranslation();
  const { name, deadline, status, school_code, number_ordered, in_review } =
    order;

  return (
    <li>
      <div className="flex flex-col justify-between py-4 space-y-2 group">
        <div className="grid grid-cols-2">
          <div className="space-y-2 ">
            <p className="text-xs ">{t("Bezeichnung")}</p>
            <p className="font-medium text-cal-primary-celadon-green-00B5B1">
              <Link to={`/group-orders/${school_code}`}>{name}</Link>
            </p>
          </div>
          <div className="space-y-2 text-right">
            <p className="text-xs ">{t("Schulcode")}</p>
            <p>{school_code}</p>
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div className="space-y-2 ">
            <p className="text-xs ">{t("Frist")}</p>
            <p>
              {deadline ? dayjs(deadline).format("DD.MM.YYYY") : "--.--.----"}
            </p>
          </div>
          <div className="space-y-2 text-right">
            <p className="text-xs ">{t("Status")}</p>
            <p className="uppercase ">
              <Link to={`/group-orders/${school_code}/status`}>
                {in_review ? (
                  <Status>{IN_REVIEW}</Status>
                ) : (
                  <Status>{status}</Status>
                )}
              </Link>
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div className="space-y-2 ">
            <p className="text-xs ">{t("Bestellungen")}</p>
            <p className="text-xl font-medium text-cal-primary-celadon-green-00B5B1 hover:text-cal-primary-celadon-green-008E8D">
              <Link to={`/group-orders/${school_code}/overview`}>
                {number_ordered}
              </Link>
            </p>
          </div>
          <div className="relative flex items-end justify-end">
            <Menu>
              {({ open }) => (
                <>
                  <Menu.Button className="inline-flex justify-center text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800">
                    <svg
                      id="Options"
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                    >
                      <circle
                        id="Ellipse_83"
                        data-name="Ellipse 83"
                        cx="18"
                        cy="18"
                        r="18"
                        fill="#e5f8f7"
                      />
                      <g
                        id="Group_774"
                        data-name="Group 774"
                        transform="translate(-1135 -371)"
                      >
                        <circle
                          id="Ellipse_79"
                          data-name="Ellipse 79"
                          cx="3"
                          cy="3"
                          r="3"
                          transform="translate(1140 392) rotate(-90)"
                          fill="#01a7a3"
                        />
                        <circle
                          id="Ellipse_80"
                          data-name="Ellipse 80"
                          cx="3"
                          cy="3"
                          r="3"
                          transform="translate(1150 392) rotate(-90)"
                          fill="#01a7a3"
                        />
                        <circle
                          id="Ellipse_81"
                          data-name="Ellipse 81"
                          cx="3"
                          cy="3"
                          r="3"
                          transform="translate(1161 392) rotate(-90)"
                          fill="#01a7a3"
                        />
                      </g>
                    </svg>
                  </Menu.Button>

                  <GroupOrderMenus open={open} order={order} />
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>
    </li>
  );
}
