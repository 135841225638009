import React, { Component } from "react";
import StepsModalLayout from "../../components/Layouts/StepsModalLayout";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import FieldCreateGroupOrder from "./FieldCreateGroupOrder";
import Loading from "../HomeScreen/Loading";
import api from "../../services/api";
import history from "../../routes/history";
import { withTranslation } from "react-i18next";
import {sendPageToGA} from "../../services/ga4";

class CreateGroupOrderScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
     sendPageToGA('/calcuso-group-order-teacher-create-go', 'GO Teacher | Step 1 | Input Name');
  }

  render() {
    const { isLoading } = this.state;
    const { t } = this.props;

    return (
      <StepsModalLayout>
        <div className="px-2">
          <div className="inline-block md:hidden">
            <h1 className="pt-4 text-xl text-cal-primary-gunmetal-black-202833">
              {t("Sammelbestellung")}
            </h1>
          </div>
          <div className="h-full py-20 md:py-5 md:px-2 md:h-96 ">
            <div className="mt-4 md:mt-20 ">
              <h3 className="text-2xl font-medium text-cal-primary-gunmetal-black-202833 ">
                {t("Geben sie der Sammelbestellung einen Namen")}
              </h3>
            </div>

            {isLoading ? (
              <Loading />
            ) : (
              <Formik
                validateOnMount
                initialValues={{
                  name: "",
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string()
                    .max(200, t("Too Long!"))
                    .required(t("Pflichtfeld")),
                })}
                onSubmit={async (values) => {
                  this.setState({ isLoading: true });

                  let groupOrderData = await api.post(
                    "/group-orders/create",
                    {},
                    values
                  );
                  const { data } = groupOrderData;
                  const { success, payload } = data;

                  if (success) {
                    const { school_code } = payload;
                    history.push(`/group-orders/${school_code}`);
                  } else {
                    this.setState({ isLoading: false });
                  }
                }}
              >
                {({ errors, touched }) => (
                  <Form className="py-4 md:py-8">
                    <Field
                      name="name"
                      component={FieldCreateGroupOrder}
                      placeholder={t("Bitte eingeben")}
                      label={t("Bezeichnung (z.B. „Klasse 5b, 2021/2022“)")}
                    />
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </StepsModalLayout>
    );
  }
}

export default withTranslation()(CreateGroupOrderScreen);
