import React, { useState } from "react";
import ProductImage from "../../components/ProductImage";
import useGlobal from "../../state/useGlobal";
import {
  InformationCircleIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/outline";
import Button from "../../components/Button";
import api from "../../services/api";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

export default function Product({ product, schoolCode, items }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { groupOrder } = globalState;
  const { isLock } = groupOrder;

  const { addToast } = useToasts();

  const selected = items
    ? items.find((item) => item.product_id.toString() === product.id)
    : undefined;

  const handleAddProduct = async () => {
    setLoading(true);
    globalActions.setLockGroupOrder(true);

    try {
      let groupOrderData = await api.put(
        `/group-orders/${schoolCode}/item`,
        {},
        { item: product.id }
      );
      const { data } = groupOrderData;
      const { success, message } = data;

      globalActions.loadGroupOrder(schoolCode, () => {
        addToast(message, {
          appearance: success ? "success" : "error",
        });

        setLoading(false);
      });
    } catch (error) {
      const { data } = error;
      const { success, message } = data;
      globalActions.loadGroupOrder(schoolCode, () => {
        addToast(message, {
          appearance: success ? "success" : "error",
        });

        setLoading(false);
      });
    }
  };

  const handleRemoveProduct = async () => {
    globalActions.openConfirmModal(async () => {
      globalActions.closeConfirmModal();
      setLoading(true);
      globalActions.setLockGroupOrder(true);

      let groupOrderData = await api.del(
        `/group-orders/${schoolCode}/item/${selected.id}`
      );
      const { data } = groupOrderData;
      const { success, message } = data;

      globalActions.loadGroupOrder(schoolCode, () => {
        addToast(message, {
          appearance: success ? "success" : "error",
        });

        setLoading(false);
      });
    });
  };

  return (
    <li
      key={product.id}
      className="relative flex flex-col col-span-1 text-center bg-white divide-y divide-gray-200 rounded-lg shadow"
    >
      <span
        onClick={() => globalActions.openProductModal(product, schoolCode)}
        className="absolute top-0 right-0 block w-5 h-5 transform translate-x-1/2 -translate-y-1/2 rounded-full cursor-pointer md:w-8 md:h-8 ring-2 ring-white bg-cal-primary-floral-white-F5F0E1"
      >
        <InformationCircleIcon className="w-5 h-5 md:w-8 md:h-8 text-cal-primary-gunmetal-black-202833" />
      </span>
      <div className="flex flex-col flex-1 p-4 md:p-8">
        <div
          className="w-full cursor-pointer "
          onClick={() => globalActions.openProductModal(product, schoolCode)}
        >
          <ProductImage src={product.images[0]} alt={product.name} />
        </div>
        <h3 className="mt-6 text-xs font-medium uppercase truncate md:text-sm text-cal-primary-gunmetal-black-202833">
          {product.name}
        </h3>
        <dl className="flex flex-col justify-between flex-grow mt-1">
          {product.brand ? (
            <dd className="text-xs md:text-sm text-cal-primary-gunmetal-black-989898">
              {t("von")} <span className="uppercase ">{product.brand}</span>
            </dd>
          ) : (
            <dd className="text-xs md:text-sm text-cal-primary-gunmetal-black-989898"></dd>
          )}
        </dl>
        {selected ? (
          <>
            <h2 className="mt-4 text-base font-medium md:text-lg text-cal-primary-gunmetal-black-202833">
              {selected.collective_price
                ? selected.collective_price
                : selected.product.collective_price
                ? selected.product.collective_price
                : selected.product.price}{" "}
              €
            </h2>
            {selected.gravur_price || selected.product.engrave.is_engrave ? (
              <h2 className="mt-2 text-xs underline md:text-sm text-cal-primary-gunmetal-black-202833">
                {t("Gravur")} +{" "}
                {selected.gravur_price
                  ? selected.gravur_price
                  : selected.product.engrave.price}{" "}
                €
              </h2>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <h2 className="mt-4 text-base font-medium md:text-lg text-cal-primary-gunmetal-black-202833">
              {product.collective_price
                ? product.collective_price
                : product.price}{" "}
              €
            </h2>
            {product.engrave.is_engrave ? (
              <h2 className="mt-2 text-xs underline md:text-sm text-cal-primary-gunmetal-black-202833">
                {t("Gravur")} + {product.engrave.price} €
              </h2>
            ) : (
              ""
            )}
          </>
        )}
        <div className="pt-4">
          {selected ? (
            <Button
              onClick={handleRemoveProduct}
              loading={loading}
              color="danger"
              disabled={isLock}
            >
              <XIcon className="w-4 h-4 md:-ml-1 md:mr-2 md:w-5 md:h-5" />
              {t("entfernen")}
            </Button>
          ) : (
            <Button
              onClick={handleAddProduct}
              loading={loading}
              color="primary"
              disabled={isLock}
            >
              <PlusIcon className="w-4 h-4 md:-ml-1 md:mr-2 md:w-5 md:h-5" />
              <span className="truncate">{t("hinzufügen")}</span>
            </Button>
          )}
        </div>
      </div>
    </li>
  );
}
