import React, {Component} from "react";
import {connect} from "../../state/useGlobal";
import Product from "./Product";

class SelectedProductsTab extends Component {
    render() {
        const {globalState, schoolCode} = this.props;
        const {groupOrder} = globalState;
        const {items} = groupOrder;
        const products = items.map((item) => {
            let product = item.product;
            product.collective_price = item.collective_price ?? product.collective_price;

            return product;
        });

        return (
            <div className="pt-8 pb-12">
                <ul className="grid grid-cols-2 gap-3 md:gap-4 md:grid-cols-3 lg:gap-4 lg:grid-cols-4">
                    {products.map((product) => (
                        <Product
                            key={product.id}
                            product={product}
                            schoolCode={schoolCode}
                            items={items}
                        />
                    ))}
                </ul>
            </div>
        );
    }
}

export default connect(SelectedProductsTab);
