export const initialState = {
  isValid: false,
};

export const setSubmitGroupOrder = (store, submitGroupOrder) => {
  store.setState({
    submitGroupOrder: {
      ...initialState,
      ...submitGroupOrder,
    },
  });
};
