import axios from "axios";
import qs from "qs";
import Config from "../config";
import history from "../routes/history";
import { FR, PT, EN, NL, IT } from "../constants/storeCode";

const STORE_API = {
  1: "default",
  4: FR,
  2: PT,
  5: EN,
  6: NL,
  3: IT,
};

const instance = axios.create({
  baseURL: Config.apiURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  transformResponse: (data) => (data ? JSON.parse(data) : undefined),
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: "brackets" });
  },
});

const METHODS = ["get", "put", "post", "patch", "delete", "head", "options"];

const httpMethods = METHODS.map(
  (method) =>
    async (url, headers = {}, data, params) => {
      const token = localStorage.getItem("token");

      const apiStore = localStorage.getItem("store")
        ? STORE_API[localStorage.getItem("store")]
        : "default";

      return instance.request({
        url: "/rest/" + apiStore + "/V1" + url,
        method,
        headers: { Authorization: `Bearer ${token}`, ...headers },
        data,
        params,
      });
    }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.isAxiosError) {
      const { status, config } = error.response || {};

      if (status === 401 && config.url !== "/group-orders/login") {
        localStorage.removeItem("token");
        localStorage.removeItem("user");

        history.push("/sign-in");
      }
    }
    return Promise.reject(error.response);
  }
);

export const [get, put, post, patch, del, head, options] = httpMethods;

export const me = async (accessToken) => {
  const { status, data } =
    (await get("/group-orders/me", {
      Authorization: `Bearer ${accessToken}`,
    })) || {};

  if (status === 200) {
    const { payload } = data;
    localStorage.setItem("user", JSON.stringify(payload));
    return payload;
  }

  return false;
};

const getByStore = async (
    url,
    storeView = "default",
    headers = {},
    data,
    params,
    method = 'GET'
) => {
  const token = localStorage.getItem("token");

  return instance.request({
    url: "/rest/" + storeView + "/V1" + url,
    method: method,
    headers: { Authorization: `Bearer ${token}`, ...headers },
    data,
    params,
  });
};

const api = {
  me,
  getByStore,
  get,
  put,
  post,
  patch,
  del,
  head,
  options,
};

export default api;
