import * as React from "react";

function BasketIcon(props) {
  const color = "#f5f0e1"
  const strokeWidth = 4
  return (
      <svg
          id="Icon_Basket_48"
          data-name="Icon Basket 48"
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          {...props}
      >
        <rect id="_48_x_48" data-name="48 x 48" width="48" height="48" fill="none"/>
        <g id="Icon_Basket" data-name="Icon Basket" transform="translate(2 3.453)">
          <path
              id="Path_210"
              data-name="Path 210"
              d="M1366.57,269.314l-.216-4.325h31.714l-1.305,26.093a2.882,2.882,0,0,1-2.876,2.739h-23.35a2.883,2.883,0,0,1-2.883-2.739l-.075-1.586"
              transform="translate(-1355.542 -253.457)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={strokeWidth}/>

          <path
              id="Path_211"
              data-name="Path 211"
              d="M1377.446,262.87v-5.045a6.487,6.487,0,0,1,6.487-6.488h0a6.488,6.488,0,0,1,6.488,6.488v5.045"
              transform="translate(-1357.265 -251.337)" fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={strokeWidth}/>

          <line
              id="Line_127"
              data-name="Line 127" x2="14.416"
              transform="translate(0 25.948)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={strokeWidth}/>

          <line
              id="Line_128"
              data-name="Line
          128" x2="12.974"
              transform="translate(5.045 20.182)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={strokeWidth}/>

          <line
              id="Line_129"
              data-name="Line 129"
              x2="12.974"
              transform="translate(5.045 31.715)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={strokeWidth}/>
        </g>
      </svg>
  )
}

export default BasketIcon