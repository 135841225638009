import React from "react";
import globalHook from "use-global-hook";

import {
  initialState as submitGroupOrder,
  setSubmitGroupOrder,
} from "./submitGroupOrder";
import { initialState as config, setConfig, loadConfig } from "./config";
import { initialState as user, setUser } from "./user";
import { initialState as store, setStore } from "./store";
import {
  initialState as groupOrders,
  setGroupOrders,
  deleteGroupOrder,
  duplicateGroupOrder,
} from "./groupOrders";
import {
  initialState as confirmModal,
  setConfirmModal,
  openConfirmModal,
  closeConfirmModal,
} from "./confirmModal";
import {
  initialState as groupOrder,
  setGroupOrder,
  loadGroupOrder,
  setLockGroupOrder,
} from "./groupOrder";
import {
  initialState as productModal,
  setProductModal,
  openProductModal,
  closeProductModal,
} from "./productModal";

const logout = (store, redirectUrl) => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");

  window.location.href = redirectUrl;
};

const initialState = {
  user,
  groupOrders,
  confirmModal,
  groupOrder,
  productModal,
  config,
  submitGroupOrder,
  store,
};

const actions = {
  setUser,
  logout,
  setGroupOrders,
  deleteGroupOrder,
  duplicateGroupOrder,
  setConfirmModal,
  openConfirmModal,
  closeConfirmModal,
  setGroupOrder,
  loadGroupOrder,
  setProductModal,
  openProductModal,
  closeProductModal,
  setConfig,
  loadConfig,
  setSubmitGroupOrder,
  setStore,
  setLockGroupOrder,
};

const useGlobal = globalHook(React, initialState, actions);
export const connect = (Component) => {
  return (props) => {
    let [globalState, globalActions] = useGlobal();
    let _props = { ...props, globalState, globalActions };
    return <Component {..._props} />;
  };
};

export default useGlobal;
