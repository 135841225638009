import React, {Component} from "react";
import {connect} from "../../state/useGlobal";
import AppLayout from "../../components/Layouts/AppLayout";
import api from "../../services/api";
import Loading from "./Loading";
import ListGroupOrder from "./ListGroupOrder";
import {Link} from "react-router-dom";
import PlusIcon from "../../components/Icons/PlusIcon";
import {MailIcon, PhoneIcon, SpeakerphoneIcon, XIcon,} from "@heroicons/react/outline";
import LoadingHelpDesk from "./LoadingHelpDesk";
import {withTranslation} from "react-i18next";
import {sendPageToGA} from "../../services/ga4";

class HomeScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isLoadingHelpDesk: true,
      hideBanner: false,
    };
  }

  async componentDidMount() {
    const { globalActions } = this.props;

    api.get("/group-orders").then((groupOrdersData) => {
      const { data } = groupOrdersData;
      const { success, payload } = data;
      if (success) {
        const { orders } = payload;
        globalActions.setGroupOrders(orders);
        this.setState({
          isLoading: false,
        });
      }
    });

    await globalActions.loadConfig();

    this.setState({
      isLoadingHelpDesk: false,
    });
    sendPageToGA('/calcuso-group-order-teacher-homepage', 'GO Teacher | Homepage');
  }

  render() {
    const { isLoading, hideBanner, isLoadingHelpDesk } = this.state;
    const { globalState, t } = this.props;
    const { groupOrders } = globalState;
    const { user, config } = globalState;
    const { help_desk } = config;

    return (
      <AppLayout>
        <header className="py-4 md:pt-10 md:pb-4">
          <div className="px-4 pb-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="flex flex-col justify-between space-y-4 md:items-center sm:space-y-0 sm:flex-row">
              <div>
                <h1 className="text-2xl font-bold md:text-3xl text-cal-primary-gunmetal-black-202833 ">
                  {t("Sammelbestellung")}
                </h1>
              </div>
              <div className="w-full md:w-auto ">
                <Link
                  to="/create"
                  className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-bold transition duration-500 ease-in-out transform border border-transparent rounded-md shadow-md md:w-auto text-cal-primary-floral-white-FEFCF6 bg-cal-primary-celadon-green-00B5B1 hover:bg-cal-primary-celadon-green-008E8D focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cal-primary-celadon-green-00B5B1 hover:translate-2 hover:scale-110"
                >
                  <PlusIcon className="w-5 h-5 mr-3 -ml-1" />
                  <span>{t("neue Sammelbestellung")}</span>
                </Link>
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="px-4 pb-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="p-6 bg-white rounded-lg sm:px-6">
              <p className="font-medium ">{t("Deine Sammelbestellungen")}</p>

              {isLoading ? (
                <Loading />
              ) : (
                <ListGroupOrder orders={groupOrders} />
              )}
            </div>

            <div className="w-full mt-10 mb-20 md:w-2/3 ">
              <section className="overflow-hidden bg-white rounded-lg shadow">
                <div className="relative px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
                  <svg
                    className="absolute left-0 transform -translate-y-24 top-full translate-x-80 lg:hidden"
                    width={784}
                    height={404}
                    fill="none"
                    viewBox="0 0 784 404"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x={0}
                          y={0}
                          width={4}
                          height={4}
                          className="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width={784}
                      height={404}
                      fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)"
                    />
                  </svg>

                  <svg
                    className="absolute hidden transform translate-x-1/2 -translate-y-1/2 lg:block right-full top-1/2"
                    width={404}
                    height={784}
                    fill="none"
                    viewBox="0 0 404 784"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="56409614-3d62-4985-9a10-7ca758a8f4f0"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x={0}
                          y={0}
                          width={4}
                          height={4}
                          className="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width={404}
                      height={784}
                      fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)"
                    />
                  </svg>

                  {isLoadingHelpDesk ? (
                    <LoadingHelpDesk />
                  ) : (
                    <div className="relative lg:flex lg:items-center">
                      <div className="hidden lg:block lg:flex-shrink-0">
                        <img
                          className="w-24 h-24 rounded-full "
                          src={help_desk?.avatar}
                          alt=""
                        />
                      </div>

                      <div className="relative lg:ml-10">
                        <blockquote className="relative">
                          <div>
                            <h4 className="text-lg font-bold">
                              {help_desk?.title},{" "}
                              {`${user.prefix ? user.prefix : ""} ${
                                user?.first_name
                              } ${user?.last_name}`}
                              {" !"}
                            </h4>
                            <p className="mt-1 ">{help_desk?.description}</p>
                          </div>
                          <footer className="mt-8">
                            <div className="flex">
                              <div className="flex-shrink-0 lg:hidden">
                                <img
                                  className="w-12 h-12 rounded-full"
                                  src={help_desk?.avatar}
                                  alt=""
                                />
                              </div>
                              <div className="ml-4 lg:ml-0">
                                <dl className="space-y-2 ">
                                  {help_desk.phone ? (
                                    <dd className="flex text-base text-cal-primary-gunmetal-black-202833">
                                      <PhoneIcon
                                        className="flex-shrink-0 w-6 h-6 text-cal-primary-gunmetal-black-202833"
                                        aria-hidden="true"
                                      />
                                      <span className="ml-3">
                                        {help_desk.phone}
                                      </span>
                                    </dd>
                                  ) : (
                                    ""
                                  )}
                                  {help_desk.email ? (
                                    <dd className="flex text-base text-cal-primary-gunmetal-black-202833">
                                      <MailIcon
                                        className="flex-shrink-0 w-6 h-6 text-cal-primary-gunmetal-black-202833"
                                        aria-hidden="true"
                                      />
                                      <span className="ml-3">
                                        {help_desk.email}
                                      </span>
                                    </dd>
                                  ) : (
                                    ""
                                  )}
                                </dl>
                              </div>
                            </div>
                          </footer>
                        </blockquote>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>

            {!hideBanner ? (
              <div className="fixed inset-x-0 bottom-0">
                <div className=" bg-cal-primary-celadon-green-01A7A3">
                  <div className="px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="flex flex-wrap items-center justify-between">
                      <div className="flex items-center flex-1 w-0">
                        <span className="flex p-2 rounded-lg bg-cal-primary-celadon-green-008E8D">
                          <SpeakerphoneIcon
                            className="w-6 h-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                        <p className="ml-3 font-medium text-white truncate">
                          <span className="md:hidden">
                            {help_desk?.video_title}
                          </span>
                          <span className="hidden md:inline">
                            {help_desk?.video_title}
                          </span>
                        </p>
                      </div>
                      <div className="flex-shrink-0 order-3 w-full mt-2 sm:order-2 sm:mt-0 sm:w-auto">
                        <a
                          href={help_desk?.video_url}
                          target="_blank"
                          rel="noreferrer"
                          className="flex items-center justify-center px-4 py-2 text-sm font-medium transition duration-500 ease-in-out transform border border-transparent rounded-md shadow-sm bg-cal-primary-floral-white-FEF9EF text-cal-primary-celadon-green-01A7A3 hover:bg-cal-primary-floral-white-F5F0E1 hover:translate-2 hover:scale-110"
                        >
                          {t("Mehr erfahren")}
                        </a>
                      </div>
                      <div className="flex-shrink-0 order-2 sm:order-3 sm:ml-3">
                        <button
                          type="button"
                          onClick={() => this.setState({ hideBanner: true })}
                          className="flex p-2 -mr-1 rounded-md hover:bg-cal-primary-celadon-green-01A7A3 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                        >
                          <span className="sr-only">Dismiss</span>
                          <XIcon
                            className="w-6 h-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </main>
      </AppLayout>
    );
  }
}

export default connect(withTranslation()(HomeScreen));
