const prod = {
  apiURL: "https://www.calcuso.de",
  gt4: "G-RLPYVEMLP4",
  ssoURL: "/calcuso_group_order/authentication",
  shopURL: "/customer/account/",
  stores: {
    1: "https://www.calcuso.de",
    4: "https://www.calcuso.com/fr",
    2: "https://www.calcuso.com/pt",
    6: "https://www.calcuso.com/nl",
    3: "https://www.calcuso.com/it",
  },
  goBackAccountURL: {
    1: "https://www.calcuso.de/customer/account/index/",
    4: "https://www.calcuso.com/fr/customer/account/index/",
    2: "https://www.calcuso.com/pt/customer/account/index/",
    6: "https://www.calcuso.com/nl/customer/account/index/",
    3: "https://www.calcuso.com/it/customer/account/index/",
  }
};

const dev = {
  apiURL: "https://staging.calcuso.com",
  gt4: "G-RLPYVEMLP4444",
  ssoURL: "/calcuso_group_order/authentication?env=dev",
  shopURL: "/customer/account/",
  stores: {
    1: "https://staging.calcuso.com",
    4: "https://staging.calcuso.com/fr",
    2: "https://staging.calcuso.com/pt",
    6: "https://staging.calcuso.com/nl",
    3: "https://staging.calcuso.com/it",
  },
  goBackAccountURL: {
    1: "https://staging.calcuso.com/customer/account/index/",
    4: "https://staging.calcuso.com/fr/customer/account/index/",
    2: "https://staging.calcuso.com/pt/customer/account/index/",
    6: "https://staging.calcuso.com/nl/customer/account/index/",
    3: "https://staging.calcuso.com/it/customer/account/index/",
  }
};

export default process.env.REACT_APP_STAGE === "prod" ? prod : dev
