import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Button({
  size,
  styleType,
  type,
  children,
  disabled,
  onClick,
  loading,
}) {
  const { t } = useTranslation();

  return (
    <button
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      className={classNames(
        "w-full md:w-auto inline-flex items-center justify-center px-4 py-2 font-medium border border-transparent truncate rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-500 ease-in-out transform hover:translate-2 hover:scale-110",
        {
          " opacity-60": loading,
          "sm:text-xl": size === "xl",
          "sm:text-sm": size === "sm",
          "sm:text-lg": size === "lg",
          "text-cal-primary-floral-white-FEFCF6 bg-cal-primary-celadon-green-00B5B1 hover:bg-cal-primary-celadon-green-01A7A3 focus:ring-cal-primary-celadon-green-00B5B1":
            styleType === "primary" && !disabled,
          "text-cal-primary-floral-white-FEFCF6 bg-cal-primary-gunmetal-black-202833 hover:bg-cal-primary-gunmetal-black-202833 focus:ring-cal-primary-gunmetal-black-202833":
            styleType === "secondary" && !disabled,
          "text-cal-primary-floral-white-FEFCF6 bg-cal-secondary-neutral-grey-d2d4d6 hover:bg-cal-secondary-neutral-grey-d2d4d6 focus:ring-cal-secondary-neutral-grey-d2d4d6": disabled,
        }
      )}
    >
      {loading ? (
        <>
          <svg
            className="w-5 h-5 mr-0 -ml-0 text-white md:-ml-1 md:mr-3 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span className="hidden truncate md:inline">
            {t("wird bearbeitet")}
          </span>
        </>
      ) : (
        children
      )}
    </button>
  );
}
