import React from "react";
import useGlobal from "../../state/useGlobal";
import ModalLayout from "./ModalLayout";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function StepsModalLayout({ children, schoolCode }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();

  return (
    <ModalLayout>
      <div className="flex items-center justify-between p-2">
        <div className="py-2 md:w-12">
          <div className="inline-block md:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="134.325"
              height="19.885"
              viewBox="0 0 134.325 19.885"
            >
              <g
                id="Logo_Calcuso"
                data-name="Logo Calcuso"
                transform="translate(0)"
              >
                <path
                  id="Path_29"
                  data-name="Path 29"
                  d="M1122.06,940.791a8.775,8.775,0,0,0-5-5.889,9.442,9.442,0,0,0-4.57-.722,8.561,8.561,0,0,0-6.457,3.378,10.1,10.1,0,0,0-2.036,5.7,10.708,10.708,0,0,0,1.532,6.561,8.686,8.686,0,0,0,7.71,4.144c.079,0,.442,0,.576-.014,3.834-.4,6.577-2.187,8-5.883A11.211,11.211,0,0,0,1122.06,940.791Zm-3.886,6.461a5.321,5.321,0,0,1-10.206-1.235,9.511,9.511,0,0,1,.2-4.7,5.1,5.1,0,0,1,5.751-3.754,5.159,5.159,0,0,1,4.6,4.564A9.322,9.322,0,0,1,1118.174,947.251Z"
                  transform="translate(-988.223 -934.135)"
                  fill="#01a7a3"
                />
                <path
                  id="Path_30"
                  data-name="Path 30"
                  d="M972.47,943.905a6.768,6.768,0,0,0-2.593-1.308c-1.073-.261-2.167-.435-3.253-.637a8.558,8.558,0,0,1-2.588-.768,1.8,1.8,0,0,1-.185-3.081,3.4,3.4,0,0,1,1.655-.55,5.206,5.206,0,0,1,4.458,1.336c.265.251.546.485.834.709a1.734,1.734,0,0,0,.525.3,1.542,1.542,0,0,0,2.137-1.966,3.389,3.389,0,0,0-.367-.887,5.414,5.414,0,0,0-2.541-2.1,9.782,9.782,0,0,0-4.055-.818,9.147,9.147,0,0,0-3.794.739,5.452,5.452,0,0,0-3.347,5.763,4.682,4.682,0,0,0,2.6,3.8,12.912,12.912,0,0,0,4.269,1.124,11.642,11.642,0,0,1,3.011.765,1.959,1.959,0,0,1,1.16,1.186,2.187,2.187,0,0,1-1.189,2.643,5.78,5.78,0,0,1-2.348.426,5.309,5.309,0,0,1-4.223-1.754,9.69,9.69,0,0,0-.85-.794,2.086,2.086,0,0,0-1.235-.5,1.44,1.44,0,0,0-1.636,1.632,4.39,4.39,0,0,0,.433,1.508,5.354,5.354,0,0,0,2.775,2.376,10.363,10.363,0,0,0,3.6.881,9.688,9.688,0,0,0,5.149-.861,5.861,5.861,0,0,0,3.083-3.466A5.091,5.091,0,0,0,972.47,943.905Z"
                  transform="translate(-862.051 -934.125)"
                  fill="#01a7a3"
                />
                <path
                  id="Path_31"
                  data-name="Path 31"
                  d="M816.846,937.329a1.92,1.92,0,0,0-2.219-.545,1.7,1.7,0,0,0-1.108,1.727c0,3.5,0,6.659,0,10.163a3.854,3.854,0,0,1-2.628,3.834,4.959,4.959,0,0,1-2.542.185,5.187,5.187,0,0,1-1.618-.562,3.8,3.8,0,0,1-1.865-3.571c-.007-3.446,0-6.543,0-9.989a1.935,1.935,0,0,0-.33-1.21,1.988,1.988,0,0,0-2.216-.591,1.64,1.64,0,0,0-1.151,1.687c-.006,1.827-.081,8.839.034,10.681a6.876,6.876,0,0,0,4.2,6.244,9.38,9.38,0,0,0,5.873.546,7.24,7.24,0,0,0,5.954-7.181c.037-3.55.019-6.752-.008-10.3A2.163,2.163,0,0,0,816.846,937.329Z"
                  transform="translate(-724.819 -936.301)"
                  fill="#01a7a3"
                />
                <path
                  id="Path_32"
                  data-name="Path 32"
                  d="M230.172,948.416a4.073,4.073,0,0,0-1.235.408,11.637,11.637,0,0,0-1.073.659,6.524,6.524,0,0,1-3.327,1.02,5.286,5.286,0,0,1-5.513-4.21,9.361,9.361,0,0,1-.178-3.816,5.811,5.811,0,0,1,1.94-3.78,5.6,5.6,0,0,1,5.75-.886,18.54,18.54,0,0,1,2.443,1.327,3.253,3.253,0,0,0,.875.347,1.477,1.477,0,0,0,1.959-1.215,2.1,2.1,0,0,0-.442-1.491,5.638,5.638,0,0,0-2.449-1.85,11.224,11.224,0,0,0-6.243-.746,8.426,8.426,0,0,0-6.565,4.635,11.341,11.341,0,0,0-.893,7.818,8.869,8.869,0,0,0,3.649,5.635,9.806,9.806,0,0,0,5.586,1.651,8.987,8.987,0,0,0,6.082-1.893,3.389,3.389,0,0,0,1.193-1.6A1.446,1.446,0,0,0,230.172,948.416Z"
                  transform="translate(-214.907 -934.035)"
                  fill="#01a7a3"
                />
                <path
                  id="Path_33"
                  data-name="Path 33"
                  d="M541.09,952.708a3.464,3.464,0,0,0-.935-.115c-2.066-.01-4.133,0-6.2,0h-.439v-.473c0-4.614,0-8.88,0-13.495a1.953,1.953,0,0,0-.351-1.239,2,2,0,0,0-2.222-.572,1.69,1.69,0,0,0-1.143,1.736c0,4.013,0,7.678,0,11.691,0,1.319-.005,2.637,0,3.955a1.64,1.64,0,0,0,1.145,1.658,2.962,2.962,0,0,0,.867.115q4.217.012,8.435,0a3.312,3.312,0,0,0,.739-.073,1.588,1.588,0,0,0,1.276-1.5A1.55,1.55,0,0,0,541.09,952.708Z"
                  transform="translate(-488.804 -936.336)"
                  fill="#01a7a3"
                />
                <path
                  id="Path_34"
                  data-name="Path 34"
                  d="M376.409,936.221a3.081,3.081,0,0,0-5.8-.236c-1.407,3.9-5.187,14.331-5.653,15.656-.37,1.053-.054,1.823.853,2.139a2.189,2.189,0,0,0,2.686-1.026,5.033,5.033,0,0,0,.354-.822c.33-.97,3.924-11.894,4.575-13.866.016-.042.06-.048.081,0,.763,2.311,3.824,11.4,4.089,12.187a.04.04,0,0,1-.038.054c-.43,0-3.2,0-4.647,0a3.462,3.462,0,0,0-.936.115,1.55,1.55,0,0,0-1.175,1.681,1.588,1.588,0,0,0,1.276,1.5,3.309,3.309,0,0,0,.739.073q3.788.007,7.577,0c.008,0,.282-.011.326-.017.874-.124,1.409-.638,1.393-1.412a3.344,3.344,0,0,0-.22-1.04C380.069,946.145,378.229,941.292,376.409,936.221Z"
                  transform="translate(-345.292 -934.055)"
                  fill="#01a7a3"
                />
                <path
                  id="Path_35"
                  data-name="Path 35"
                  d="M654.269,948.416a4.073,4.073,0,0,0-1.235.408,11.635,11.635,0,0,0-1.073.659,6.524,6.524,0,0,1-3.327,1.02,5.286,5.286,0,0,1-5.513-4.21,9.36,9.36,0,0,1-.178-3.816,5.811,5.811,0,0,1,1.94-3.78,5.6,5.6,0,0,1,5.75-.886,18.541,18.541,0,0,1,2.443,1.327,3.255,3.255,0,0,0,.875.347,1.477,1.477,0,0,0,1.959-1.215,2.1,2.1,0,0,0-.442-1.491,5.638,5.638,0,0,0-2.449-1.85,11.224,11.224,0,0,0-6.243-.746,8.426,8.426,0,0,0-6.565,4.635,11.341,11.341,0,0,0-.893,7.818,8.869,8.869,0,0,0,3.649,5.635,9.806,9.806,0,0,0,5.586,1.651,8.987,8.987,0,0,0,6.082-1.893,3.388,3.388,0,0,0,1.193-1.6A1.446,1.446,0,0,0,654.269,948.416Z"
                  transform="translate(-583.792 -934.035)"
                  fill="#01a7a3"
                />
              </g>
            </svg>
          </div>
          <div className="hidden md:inline-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="208.325"
              height="29.885"
              viewBox="0 0 208.325 29.885"
            >
              <g
                id="Logo_Calcuso"
                data-name="Logo Calcuso"
                transform="translate(0)"
              >
                <path
                  id="Path_29"
                  data-name="Path 29"
                  d="M1132.027,944.13a13.311,13.311,0,0,0-7.755-8.851,15.065,15.065,0,0,0-7.088-1.084,13.41,13.41,0,0,0-10.015,5.077,14.893,14.893,0,0,0-3.158,8.573,15.7,15.7,0,0,0,2.376,9.861c2.792,4.287,6.915,6.152,11.957,6.228.123,0,.685,0,.893-.021,5.946-.6,10.2-3.287,12.4-8.841A16.357,16.357,0,0,0,1132.027,944.13ZM1126,953.839a7.936,7.936,0,0,1-7.782,5.014c-4.276-.1-7.178-2.565-8.046-6.87a13.869,13.869,0,0,1,.312-7.057,7.917,7.917,0,0,1,8.919-5.642c3.877.473,6.374,2.927,7.133,6.859A13.6,13.6,0,0,1,1126,953.839Z"
                  transform="translate(-924.459 -934.127)"
                  fill="#989898"
                />
                <path
                  id="Path_30"
                  data-name="Path 30"
                  d="M979.94,948.817a10.626,10.626,0,0,0-4.022-1.965c-1.664-.392-3.36-.654-5.045-.958a13.591,13.591,0,0,1-4.014-1.154,2.656,2.656,0,0,1-.286-4.631,5.4,5.4,0,0,1,2.567-.827,8.248,8.248,0,0,1,6.913,2.007c.411.377.847.73,1.293,1.065a2.711,2.711,0,0,0,.815.457,2.646,2.646,0,0,0,2.686-.308,2.463,2.463,0,0,0,.628-2.647,5,5,0,0,0-.569-1.333,8.343,8.343,0,0,0-3.94-3.155,15.589,15.589,0,0,0-6.289-1.229,14.58,14.58,0,0,0-5.884,1.111,8.162,8.162,0,0,0-5.191,8.662,7.042,7.042,0,0,0,4.039,5.717c2.069,1.1,4.363,1.326,6.621,1.689a18.529,18.529,0,0,1,4.67,1.15,2.99,2.99,0,0,1,1.8,1.782,3.254,3.254,0,0,1-1.844,3.972,9.218,9.218,0,0,1-3.641.64,8.364,8.364,0,0,1-6.549-2.636,14.863,14.863,0,0,0-1.318-1.193,3.291,3.291,0,0,0-1.915-.755,2.2,2.2,0,0,0-2.537,2.452,6.445,6.445,0,0,0,.672,2.267,8.236,8.236,0,0,0,4.3,3.571,16.493,16.493,0,0,0,5.585,1.324,15.45,15.45,0,0,0,7.985-1.294,8.908,8.908,0,0,0,4.781-5.209A7.5,7.5,0,0,0,979.94,948.817Z"
                  transform="translate(-808.691 -934.118)"
                  fill="#989898"
                />
                <path
                  id="Path_31"
                  data-name="Path 31"
                  d="M825.5,937.67a3.04,3.04,0,0,0-3.441-.819,2.547,2.547,0,0,0-1.718,2.6c0,5.266.005,10.008,0,15.273a5.8,5.8,0,0,1-4.075,5.762,7.924,7.924,0,0,1-3.942.278,8.218,8.218,0,0,1-2.509-.845,5.671,5.671,0,0,1-2.893-5.366c-.011-5.179-.005-9.834,0-15.013a2.841,2.841,0,0,0-.512-1.819,3.146,3.146,0,0,0-3.436-.888,2.47,2.47,0,0,0-1.785,2.535c-.01,2.746-.125,13.284.053,16.053a10.339,10.339,0,0,0,6.509,9.384,14.977,14.977,0,0,0,9.108.82,10.977,10.977,0,0,0,9.233-10.792c.058-5.335.03-10.147-.013-15.482A3.184,3.184,0,0,0,825.5,937.67Z"
                  transform="translate(-682.774 -936.125)"
                  fill="#989898"
                />
                <path
                  id="Path_32"
                  data-name="Path 32"
                  d="M238.582,955.647a6.462,6.462,0,0,0-1.916.613,18.178,18.178,0,0,0-1.664.99,10.351,10.351,0,0,1-5.16,1.532,8.16,8.16,0,0,1-8.55-6.328,13.645,13.645,0,0,1-.276-5.735,8.646,8.646,0,0,1,3.008-5.682,8.9,8.9,0,0,1,8.917-1.331,29.069,29.069,0,0,1,3.789,1.995,5.142,5.142,0,0,0,1.357.522c1.643.318,2.87-.429,3.038-1.827a3.085,3.085,0,0,0-.685-2.24,8.723,8.723,0,0,0-3.8-2.781,17.92,17.92,0,0,0-9.682-1.121,13.061,13.061,0,0,0-10.182,6.966,16.57,16.57,0,0,0-1.384,11.75,13.3,13.3,0,0,0,5.659,8.468,15.56,15.56,0,0,0,8.663,2.482c5.083-.042,7.7-1.474,9.432-2.845a5.11,5.11,0,0,0,1.85-2.408A2.192,2.192,0,0,0,238.582,955.647Z"
                  transform="translate(-214.907 -934.035)"
                  fill="#989898"
                />
                <path
                  id="Path_33"
                  data-name="Path 33"
                  d="M547.31,960.763a5.533,5.533,0,0,0-1.451-.173c-3.2-.014-6.41-.007-9.615-.007h-.68v-.71c0-6.935,0-13.346,0-20.281a2.869,2.869,0,0,0-.544-1.862,3.16,3.16,0,0,0-3.447-.86,2.542,2.542,0,0,0-1.773,2.609c0,6.031,0,11.539,0,17.57,0,1.982-.008,3.963,0,5.944a2.469,2.469,0,0,0,1.776,2.492,4.73,4.73,0,0,0,1.344.173q6.541.018,13.082,0a5.3,5.3,0,0,0,1.145-.11,2.409,2.409,0,0,0,1.979-2.26A2.336,2.336,0,0,0,547.31,960.763Z"
                  transform="translate(-466.22 -936.158)"
                  fill="#989898"
                />
                <path
                  id="Path_34"
                  data-name="Path 34"
                  d="M382.8,937.309a4.83,4.83,0,0,0-8.992-.354c-2.183,5.858-8.045,21.537-8.768,23.529-.574,1.583-.083,2.74,1.323,3.215a3.443,3.443,0,0,0,4.165-1.542,7.444,7.444,0,0,0,.549-1.235c.511-1.458,6.086-17.875,7.1-20.839.024-.063.093-.072.126,0,1.183,3.473,5.931,17.135,6.341,18.316a.061.061,0,0,1-.059.081c-.666,0-4.967,0-7.206.006a5.533,5.533,0,0,0-1.451.173,2.336,2.336,0,0,0-1.822,2.526,2.41,2.41,0,0,0,1.979,2.26,5.291,5.291,0,0,0,1.145.11q5.875.01,11.751,0c.012,0,.438-.017.506-.026,1.355-.187,2.185-.959,2.161-2.123a4.893,4.893,0,0,0-.342-1.563C388.477,952.223,385.623,944.93,382.8,937.309Z"
                  transform="translate(-334.541 -934.053)"
                  fill="#989898"
                />
                <path
                  id="Path_35"
                  data-name="Path 35"
                  d="M662.678,955.647a6.463,6.463,0,0,0-1.916.613,18.185,18.185,0,0,0-1.664.99,10.351,10.351,0,0,1-5.16,1.532,8.16,8.16,0,0,1-8.55-6.328,13.644,13.644,0,0,1-.276-5.735,8.645,8.645,0,0,1,3.008-5.682,8.9,8.9,0,0,1,8.917-1.331,29.076,29.076,0,0,1,3.789,1.995,5.142,5.142,0,0,0,1.357.522c1.643.318,2.87-.429,3.039-1.827a3.085,3.085,0,0,0-.685-2.24,8.723,8.723,0,0,0-3.8-2.781,17.92,17.92,0,0,0-9.682-1.121,13.06,13.06,0,0,0-10.182,6.966,16.569,16.569,0,0,0-1.384,11.75,13.3,13.3,0,0,0,5.659,8.468,15.56,15.56,0,0,0,8.663,2.482c5.083-.042,7.7-1.474,9.432-2.845a5.11,5.11,0,0,0,1.85-2.408A2.192,2.192,0,0,0,662.678,955.647Z"
                  transform="translate(-553.375 -934.035)"
                  fill="#989898"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="hidden md:block">
          <h3 className="text-sm font-medium leading-6 text-gray-900 md:text-base lg:text-xl">
            <span className="block lg:hidden">
              {schoolCode ? schoolCode : t("Sammelbestellung")}
            </span>
            <span className="hidden lg:block">
              {t("Sammelbestellung")} {schoolCode ? " – " + schoolCode : ""}
            </span>
          </h3>
        </div>
        <div className="w-8 text-right md:w-12 ">
          <Link
            to="/"
            className="relative inline-flex items-center text-sm font-medium text-cal-primary-celadon-green-008E8D hover:text-cal-primary-celadon-green-01A7A3 "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-8 h-8 lg:w-12 lg:h-12"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </Link>
        </div>
      </div>
      {children}
    </ModalLayout>
  );
}
