import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Button({
  onClick,
  children,
  loading,
  disabled,
  color,
  size = "sm",
  rounded = "full",
  width,
  type = "button",
}) {
  const { t } = useTranslation();
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={classNames(
        "inline-flex items-center truncate justify-center px-3 md:px-4 py-2 font-medium text-center text-white border border-transparent shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-500 ease-in-out transform hover:translate-2 hover:scale-110",
        {
          " opacity-60": loading,
          "rounded-full": rounded === "full",
          "rounded-md": rounded === "md",
          "bg-cal-primary-celadon-green-00B5B1 hover:bg-cal-primary-celadon-green-01A7A3 focus:ring-cal-primary-celadon-green-01A7A3":
            color === "primary" && !disabled,
          " bg-cal-secondary-fiery-rose-f74e6c hover:bg-cal-secondary-fiery-rose-f74e6c focus:ring-cal-secondary-fiery-rose-f74e6c":
            color === "danger" && !disabled,
          "text-cal-primary-floral-white-FEFCF6 bg-cal-secondary-neutral-grey-d2d4d6 hover:bg-cal-secondary-neutral-grey-d2d4d6 focus:ring-cal-secondary-neutral-grey-d2d4d6": disabled,
          "w-full": width === "full",
          "text-xs md:text-sm": size === "sm",
          "text-xs": size === "xs",
        }
      )}
    >
      {loading ? (
        <>
          <svg
            className="w-5 h-5 text-white md:mr-3 md:-ml-1 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span className="hidden md:inline-block md:text-base">
            {t("wird bearbeitet")}
          </span>
        </>
      ) : (
        children
      )}
    </button>
  );
}
