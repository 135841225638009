import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import api from "../services/api";
import useGlobal from "../state/useGlobal";
import { useTranslation } from "react-i18next";
import { STORE as M2_STOTE } from "../constants/storeCode";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Authentication() {
  let query = useQuery();
  const history = useHistory();
  const { i18n } = useTranslation();
  let token = query.get("token");
  let storeId = query.get("store-id");
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();

  useEffect(() => {
    if (storeId) {
      i18n.changeLanguage(M2_STOTE[storeId]);
      globalActions.setStore(storeId);
    }

    async function authenticateToken() {
      const { data } = await api.post("/group-orders/auth", {}, { token });
      const { success, payload } = data;
      if (success) {
        const { access_token } = payload;

        localStorage.setItem("token", access_token);

        const user = await api.me(access_token);
        globalActions.setUser(user);

        i18n.changeLanguage(M2_STOTE[user.store_id]);
        globalActions.setStore(user.store_id);

        history.push("/");
      }
    }
    authenticateToken();
  }, [globalActions, history, token, storeId, i18n]);

  return <></>;
}
