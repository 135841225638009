import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

class Tab extends Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  handleOnClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  render() {
    const {
      handleOnClick,
      props: { active, label, badge, underline, t },
    } = this;

    return (
      <div
        onClick={handleOnClick}
        className={classNames(
          "md:px-8 py-3 rounded-md text-sm md:text-base truncate rounded-b-none cursor-pointer text-cal-primary-gunmetal-black-202833 whitespace-nowrap",
          {
            "md:bg-cal-primary-floral-white-E0DACC": !active,
            " bg-cal-primary-floral-white-F5F0E1 font-bold ": active,
          }
        )}
      >
        <div className="inline-block mr-2 md:hidden">
          {Number.isInteger(badge) ? (
            <span className=" bg-cal-secondary-fiery-rose-f74e6c text-white ml-2 py-0.5 px-2.5 rounded-full text-sm no-underline ">
              {badge}
            </span>
          ) : (
            ""
          )}
        </div>
        <span className={underline && 'underline sm:no-underline'}>{t(label)}</span>
        <div className="hidden md:inline-block">
          {Number.isInteger(badge) ? (
            <span className=" bg-cal-secondary-fiery-rose-f74e6c text-white ml-2 py-0.5 px-2.5 rounded-full text-sm no-underline ">
              {badge}
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Tab);
