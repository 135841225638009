import classNames from "classnames";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "../../state/useGlobal";
import { ACTIVE, EXPIRED, SHARE } from "../../constants";
import { withTranslation } from "react-i18next";

class Step extends Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  handleOnClick = () => {
    const { globalState } = this.props;
    const { groupOrder } = globalState;
    const { status } = groupOrder;
    const { label, onClick } = this.props;

    switch (label) {
      case SHARE:
        if (status === ACTIVE || status === EXPIRED) {
          onClick(label);
        }
        break;

      default:
        onClick(label);
        break;
    }
  };

  render() {
    const {
      handleOnClick,
      props: { active, label, first, disabled, t },
    } = this;

    return (
      <li
        onClick={handleOnClick}
        className={classNames("relative overflow-hidden md:flex-1", {
          "bg-cal-primary-celadon-green-00B5B1": active,
          " text-cal-primary-floral-white-FEFCF6": active,
          "  text-cal-secondary-neutral-grey-d2d4d6 ": disabled & !active,
        })}
      >
        <div
          className={classNames(
            "overflow-hidden border-2 border-b-0 border-l-0 border-r-0 border-cal-primary-celadon-green-00B5B1 md:border-0",
            {
              "border-t-0": first,
              "md:border-l-2": !first,
              "bg-cal-primary-celadon-green-00B5B1": active,
            }
          )}
        >
          <div className="cursor-pointer group">
            <span className="flex flex-col items-center py-2 text-xs md:text-sm">
              <span className="tracking-wide ">{t(label)}</span>
            </span>
          </div>
        </div>
      </li>
    );
  }
}

export default connect(withTranslation()(Step));
