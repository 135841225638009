import "./App.css";
import { connect } from "./state/useGlobal";

import React, { PureComponent, Suspense } from "react";
import Routes from "./routes";
import Loading from "./components/Loading";
import { HashRouter as Router } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

class App extends PureComponent {
  render() {
    return (
      <ToastProvider autoDismiss placement="bottom-right">
        <Router>
          <Suspense fallback={<Loading />}>
            <Routes />
          </Suspense>
        </Router>
      </ToastProvider>
    );
  }
}

export default connect(App);
