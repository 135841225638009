import React from "react";
import { useTranslation } from "react-i18next";

function keyframes() {
  return {
    __html:
      ".simple-loader {border-top-color: #3498db;-webkit-animation: spinner 1.5s linear infinite;animation: spinner 1.5s linear infinite;}@-webkit-keyframes spinner {0% {-webkit-transform: rotate(0deg);}100% {-webkit-transform: rotate(360deg);}}@keyframes spinner {0% {transform: rotate(0deg);}100% {transform: rotate(360deg);}}",
  };
}

export default function SimpleLoading() {
  const { t } = useTranslation();
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex flex-col items-center justify-center w-full h-screen overflow-hidden bg-gray-700 opacity-75">
      <div className="w-12 h-12 mb-4 ease-linear border-4 border-t-4 border-gray-200 rounded-full simple-loader"></div>
      <h2 className="text-xl font-semibold text-center text-white">
        {t("Wird geladen...")}
      </h2>
      <p className="w-full text-xs text-center text-white md:w-1/3 md:text-base">
        {t(
          "Dies kann einige Sekunden dauern. Bitte schließen Sie diese Seite nicht."
        )}
      </p>
      <style dangerouslySetInnerHTML={keyframes()} />
    </div>
  );
}
