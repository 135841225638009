import * as React from "react";

function OneUserIcon(props) {
  const { activeIcon, className } = props;
  return (
    <svg
      data-name="Icon User 32"
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      className={className}
    >
      <path data-name="48 x 48" fill="none" d="M0 0h32v32H0z" />
      <g
        data-name="Icon User"
        transform="translate(3 3)"
        fill="none"
        stroke={activeIcon ? "#01A7A3" : "#989898"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path
          data-name="Path 212"
          d="M0 26a11.818 11.818 0 0111.818-11.818h2.364A11.818 11.818 0 0126 26"
        />
        <circle
          data-name="Ellipse 61"
          cx={7}
          cy={7}
          r={7}
          transform="translate(6)"
        />
      </g>
    </svg>
  );
}

export default OneUserIcon;
