import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useGlobal from "../state/useGlobal";
import ProductImageDetail from "./ProductImageDetail";
import { XIcon, PlusIcon, CurrencyEuroIcon } from "@heroicons/react/outline";
import { useToasts } from "react-toast-notifications";
import api from "../services/api";
import Button from "./Button";
import { useTranslation } from "react-i18next";

const Image = ({ src, alt }) => {
  const [errored, setErrored] = useState(false);

  if (errored) {
    return (
      <svg
        className="w-8 h-8 text-gray-300 bg-white border-2 rounded-full md:w-10 md:h-10 border-cal-primary-floral-white-EFE9D6"
        preserveAspectRatio="none"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 200 200"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeWidth="1"
          d="M0 0l200 200M0 200L200 0"
        ></path>
      </svg>
    );
  } else {
    return (
      <img
        className="inline-block w-8 h-8 p-1 border-2 rounded-full md:w-10 md:h-10 border-cal-primary-floral-white-EFE9D6 "
        src={src}
        alt={alt}
        onError={setErrored}
      />
    );
  }
};

export default function ProductModal() {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const { productModal, groupOrder } = globalState;
  const { open, product, schoolCode } = productModal;
  const {
    name,
    price,
    collective_price,
    description,
    brand,
    images,
    tier_prices,
    engrave,
    related_products,
  } = product;
  const { items } = groupOrder;
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const selected = items
    ? items.find((item) => item.product_id.toString() === product.id)
    : undefined;

  const handleAddProduct = async () => {
    setLoading(true);

    try {
      let groupOrderData = await api.put(
        `/group-orders/${schoolCode}/item`,
        {},
        { item: product.id }
      );
      const { data } = groupOrderData;
      const { success, message } = data;

      globalActions.loadGroupOrder(schoolCode, () => {
        addToast(message, { appearance: success ? "success" : "error" });

        setLoading(false);
      });
    } catch (error) {
      const { data } = error;
      const { success, message } = data;
      globalActions.loadGroupOrder(schoolCode, () => {
        addToast(message, {
          appearance: success ? "success" : "error",
        });

        setLoading(false);
      });
    }
  };

  const handleRemoveProduct = async () => {
    setLoading(true);

    let groupOrderData = await api.del(
      `/group-orders/${schoolCode}/item/${selected.id}`
    );
    const { data } = groupOrderData;
    const { success, message } = data;

    globalActions.loadGroupOrder(schoolCode, () => {
      addToast(message, { appearance: success ? "success" : "error" });

      setLoading(false);
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        open={open}
        onClose={globalActions.closeProductModal}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl max-w-prose sm:my-8 sm:align-middle sm:p-6">
              <div className="absolute top-0 right-0 block pt-4 pr-4">
                <button
                  type="button"
                  className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cal-primary-celadon-green-01A7A3"
                  onClick={globalActions.closeProductModal}
                >
                  <span className="sr-only">Close</span>
                  <XIcon
                    className="w-8 h-8 text-cal-primary-gunmetal-black-202833"
                    aria-hidden="true"
                  />
                </button>
              </div>
              {product ? (
                <div className="min-w-full px-2 py-8 m-auto md:p-8">
                  <div className="flex items-center justify-center w-full">
                    <ProductImageDetail src={images[0]} alt={name} />
                  </div>
                  <div className="mt-8 text-center">
                    <h2 className="text-base font-medium text-gray-900 md:text-lg">
                      {name}
                    </h2>
                    {brand ? (
                      <p className="text-xs">
                        {t("von")} {brand}
                      </p>
                    ) : (
                      ""
                    )}
                    <h2 className="mt-4 text-base font-medium md:text-lg text-cal-primary-gunmetal-black-202833">
                      {collective_price ? collective_price : price} €
                    </h2>
                    {engrave.is_engrave ? (
                      <h2 className="mt-2 text-xs underline md:text-sm text-cal-primary-gunmetal-black-202833">
                        {t("Gravur")} + {engrave.price} €
                      </h2>
                    ) : (
                      ""
                    )}
                    <div className="mt-4">
                      {selected ? (
                        <Button
                          onClick={handleRemoveProduct}
                          loading={loading}
                          color="danger"
                        >
                          <XIcon className="w-5 h-5 mr-2 -ml-1" />
                          {t("entfernen")}
                        </Button>
                      ) : (
                        <Button
                          onClick={handleAddProduct}
                          loading={loading}
                          color="primary"
                        >
                          <PlusIcon className="w-5 h-5 mr-2 -ml-1" />
                          {t("hinzufügen")}
                        </Button>
                      )}
                    </div>
                  </div>
                  {!collective_price && tier_prices.length > 0 ? (
                    <div className="mt-8">
                      <div className="flex items-center">
                        <h4 className="flex-shrink-0 pr-4 text-xs font-semibold tracking-wider uppercase bg-white md:text-sm text-cal-primary-celadon-green-01A7A3">
                          {t("Preise")}
                        </h4>
                        <div className="flex-1 border-t-2 border-gray-200" />
                      </div>
                      <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                        {tier_prices.map((tier_price) => (
                          <li
                            key={tier_price.number}
                            className="flex items-start lg:col-span-1"
                          >
                            <div className="flex-shrink-0">
                              <CurrencyEuroIcon
                                className="w-5 h-5 text-cal-primary-celadon-green-01A7A3"
                                aria-hidden="true"
                              />
                            </div>
                            <p className="ml-3 font-medium text-cal-primary-gunmetal-black-202833">
                              {t("ab")}{" "}
                              <span className=" text-cal-secondary-fiery-rose-f74e6c">
                                {tier_price.number}
                              </span>{" "}
                              {t("Stück")}
                            </p>
                            <p className="ml-3 font-medium text-gray-700">
                              <span>{tier_price.price}</span> €
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}

                  {related_products.length > 0 ? (
                    <div className="mt-8">
                      <h4 className="flex-shrink-0 pr-4 text-xs font-semibold tracking-wider uppercase bg-white md:text-sm text-cal-primary-celadon-green-01A7A3">
                        {t(
                          "Folgende Accessoires werden automatisch zu diesem Produkt vorgeschlagen"
                        )}
                      </h4>
                      <div className="mt-4 space-y-2">
                        {related_products.map((related_product, key) => (
                          <div key={key} className="flex-shrink-0 block group">
                            <div className="flex items-center">
                              <div>
                                <Image
                                  src={related_product.images[0]}
                                  alt={related_product.name}
                                />
                              </div>
                              <div className="ml-3">
                                <p className="text-xs font-medium text-gray-700 md:text-sm group-hover:text-gray-900">
                                  {related_product.name}
                                </p>
                                <p className="text-xs font-medium text-gray-700 group-hover:text-gray-900">
                                  {related_product.collective_price
                                    ? related_product.collective_price
                                    : related_product.price}{" "}
                                  €
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="mt-8">
                    <div className="flex items-center">
                      <h4 className="flex-shrink-0 pr-4 text-xs font-semibold tracking-wider uppercase bg-white md:text-sm text-cal-primary-celadon-green-01A7A3">
                        {t("Beschreibung")}
                      </h4>
                      <div className="flex-1 border-t-2 border-gray-200" />
                    </div>
                    <div
                      className="mt-4 text-xs md:text-sm text-cal-primary-gunmetal-black-202833"
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    ></div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
