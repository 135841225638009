import * as React from "react";

function DeleteIcon(props) {
  return (
    <svg
      data-name="Icon Trash 16"
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path data-name="48 x 48" fill="none" d="M0 0h16v16H0z" />
      <g
        data-name="Group 667"
        transform="translate(2 .722)"
        fill="none"
        stroke="#f5f0e1"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          data-name="Path 254"
          d="M10.849 4.086v9.7a.766.766 0 01-.766.766H1.912a.766.766 0 01-.766-.766v-9.7"
        />
        <rect
          data-name="Rectangle 504"
          width={11.995}
          height={2.106}
          rx={1.053}
          transform="translate(0 1.834)"
        />
        <path
          data-name="Path 255"
          d="M3.775 1.553V.63a.63.63 0 01.63-.63H7.59a.63.63 0 01.63.63v.923"
        />
        <g data-name="Group 664">
          <path data-name="Line 152" d="M3.381 5.926v6.789" />
          <path data-name="Line 153" d="M5.998 5.926v6.789" />
          <path data-name="Line 154" d="M8.615 5.926v6.789" />
        </g>
      </g>
    </svg>
  );
}

export default DeleteIcon;
