import classNames from "classnames";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "../../state/useGlobal";
import { ACTIVE, EXPIRED, SHARE } from "../../constants";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { withTranslation } from "react-i18next";

class StepMobile extends Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  handleOnClick = () => {
    const { globalState } = this.props;
    const { groupOrder } = globalState;
    const { status } = groupOrder;
    const { label, onClick } = this.props;

    switch (label) {
      case SHARE:
        if (status === ACTIVE || status === EXPIRED) {
          onClick(label);
        }
        break;

      default:
        onClick(label);
        break;
    }
  };

  render() {
    const {
      handleOnClick,
      props: { active, label, first, t },
    } = this;

    return (
      <li onClick={handleOnClick}>
        <div className="flex items-center">
          {!first ? (
            <ChevronRightIcon
              className="flex-shrink-0 w-5 h-5 text-cal-primary-gunmetal-black-989898"
              aria-hidden="true"
            />
          ) : (
            ""
          )}
          <div
            className={classNames("ml-3 mr-1 text-xs  ", {
              " text-cal-primary-gunmetal-black-202833 font-bold": active,
              "text-cal-primary-gunmetal-black-989898": !active,
            })}
          >
            {t(label)}
          </div>
        </div>
      </li>
    );
  }
}

export default connect(withTranslation()(StepMobile));
