import React, { Component } from "react";
import FilterCategories from "../../components/FilterCategories";
import Pagination from "../../components/Pagination";
import api from "../../services/api";
import Loading from "./Loading";
import { connect } from "../../state/useGlobal";
import Product from "./Product";
import { withTranslation } from "react-i18next";

function debounce(a, b, c) {
  var d, e;
  return function () {
    function h() {
      /* eslint-disable no-unused-expressions */
      // eslint-disable-next-line
      (d = null), c || (e = a.apply(f, g));
    }
    var f = this,
      g = arguments;
    return (
      clearTimeout(d), (d = setTimeout(h, b)), c && !d && (e = a.apply(f, g)), e
    );
  };
}

class AllProductsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
      isLoading: true,
      total: 0,
      currentPage: 1,
      pageLimit: 32,
      products: [],
      filter: false,
      code: this.props.schoolCode,
    };
  }

  componentDidMount() {
    this._searchProducts();
  }

  _searchProducts = async () => {
    const { query, currentPage, filter } = this.state;
    // Load async data.
    let productsData = await api.get("/group-orders/products", null, null, {
      query,
      page: currentPage,
      filter,
      code: this.props.schoolCode,
    });

    const { data } = productsData;
    const { success, payload } = data;
    if (success) {
      const { products } = payload;
      const { total } = products;
      const totalPage = parseInt(Math.ceil(total / this.state.pageSize));

      this.setState({
        ...products,
        totalPage,
        isLoading: false,
      });
    }
  };

  handleSearchThrottled = debounce(async (query) => {
    this.setState(
      { query, currentPage: 1, filter: false, isLoading: true, code: this.props.schoolCode },
      () => this._searchProducts()
    );
  }, 500);

  handlePageChanged = async ({ currentPage }) => {
    if (this.state.currentPage !== currentPage) {
      this.setState({ currentPage, isLoading: true }, () =>
        this._searchProducts()
      );
    }
  };

  handleFilterCategories = (filter) => {
    this.setState({ currentPage: 1, query: "", filter, isLoading: true }, () =>
      this._searchProducts()
    );
  };

  render() {
    const { isLoading, products, total, pageLimit, currentPage, query } =
      this.state;
    const { schoolCode, globalState, t } = this.props;
    const { groupOrder } = globalState;
    const { items } = groupOrder;

    return (
      <div>
        <div className="w-full">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-cal-primary-celadon-green-01A7A3"
                x-description="Heroicon name: solid/search"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              id="search"
              name="search"
              className="block w-full py-2 pl-10 pr-3 text-sm placeholder-gray-500 bg-white border-2 rounded-md border-cal-primary-celadon-green-01A7A3 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-cal-primary-celadon-green-01A7A3 focus:border-cal-primary-celadon-green-01A7A3 md:text-base"
              placeholder={t("Produkt suchen")}
              type="search"
              onChange={(e) => {
                this.handleSearchThrottled(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="sm:mt-4 mt-2">
          <h3>{t("Beliebte Kategorien")}</h3>
          <FilterCategories onFilter={this.handleFilterCategories} />
        </div>
        <div className="mt-8">
          <div className="mb-4 md:mb-8">
            <Pagination
              totalRecords={total}
              pageLimit={pageLimit}
              pageNeighbours={1}
              onPageChanged={this.handlePageChanged}
              currentPage={currentPage}
            />
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {products.length > 0 ? (
                <ul className="grid grid-cols-2 gap-3 md:gap-4 md:grid-cols-3 lg:gap-4 lg:grid-cols-4">
                  {products.map((product) => (
                    <Product
                      key={product.id}
                      product={product}
                      schoolCode={schoolCode}
                      items={items}
                    />
                  ))}
                </ul>
              ) : (
                <div className="space-y-2 ">
                  <h3 className="font-medium">
                    {t("Suchergebnisse für")}: {query}
                  </h3>
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "Wir konnten keine Artikel oder Marke mit Ihrem Suchbegriff finden."
                      ),
                    }}
                  ></h3>
                </div>
              )}
            </>
          )}
          <div className="pb-16 mt-4 md:mt-8">
            <Pagination
              totalRecords={total}
              pageLimit={pageLimit}
              pageNeighbours={1}
              onPageChanged={this.handlePageChanged}
              currentPage={currentPage}
              scrollTop={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(withTranslation()(AllProductsTab));
