import React from "react";
import Button from "../../components/Forms/Button";
import { useTranslation } from "react-i18next";

export default function FieldCreateGroupOrder({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, isValid }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) {
  const { t } = useTranslation();
  const { name } = field;
  const { label } = props;
  return (
    <div>
      <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-6 md:grid-cols-6">
        <div className="md:col-span-3">
          <label
            htmlFor={name}
            className="block text-xs sm:text-sm text-cal-primary-gunmetal-black-989898"
          >
            {label}
          </label>
          <div className="mt-1">
            <input
              type="text"
              className={`sm:text-xl block w-full border-2 rounded-md shadow-sm border-cal-primary-gunmetal-black-989898 focus:ring-cal-primary-celadon-green-01A7A3 focus:border-cal-primary-celadon-green-01A7A3`}
              {...field}
              {...props}
            />
          </div>
          <p className="block mt-2 text-sm md:hidden text-cal-secondary-fiery-rose-f74e6c ">
            {touched[field.name] && errors[field.name] && (
              <>{errors[field.name]}</>
            )}
          </p>
        </div>
        <div className="flex items-end md:col-span-3">
          <Button
            size="xl"
            styleType="primary"
            type="submit"
            disabled={!isValid}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 mr-3 -ml-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="truncate ">{t("Sammelbestellung erstellen")}</span>
          </Button>
        </div>
      </div>
      <p className="hidden mt-2 text-sm md:block text-cal-secondary-fiery-rose-f74e6c">
        {touched[field.name] && errors[field.name] && <>{errors[field.name]}</>}
      </p>
    </div>
  );
}
