import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className=" bg-cal-primary-celadon-green-E5F8F7">
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">

        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-base text-center text-cal-primary-gunmetal-black-202833">
            &copy; 2021 {t("CALCUSO, Inc. All rights reserved.")}
          </p>
        </div>
      </div>
    </footer>
  );
}
