import * as React from "react";

function PlusIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={19.941}
      height={19.135}
      viewBox="0 0 19.941 19.135"
      {...props}
    >
      <g
        data-name="Group 712"
        fill="none"
        stroke="#fef9ef"
        strokeLinecap="round"
        strokeWidth={2}
      >
        <path data-name="Line 169" d="M18.941 10H1" />
        <path data-name="Line 170" d="M9.97 18.135V1" />
      </g>
    </svg>
  );
}

export default PlusIcon;
