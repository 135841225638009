import React from "react";
import { Menu, Transition } from "@headlessui/react";
import useGlobal from "../state/useGlobal";
import EditIcon from "./Icons/EditIcon";
import PrintIcon from "./Icons/PrintIcon";
import CopyIcon from "./Icons/CopyIcon";
import DeleteIcon from "./Icons/DeleteIcon";
import { useToasts } from "react-toast-notifications";
import { ACTIVE, EXPIRED } from "../constants";
import { useTranslation } from "react-i18next";
import BasketIcon from "./Icons/BasketIcon";
import {sendPageToGA} from "../services/ga4";

export default function GroupOrderMenus({ open, order }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();

  const { t } = useTranslation();

  const { addToast } = useToasts();

  let school_code = order?.school_code;
  let print_url = order?.print_url;
  let status = order?.status;
  let number_ordered = order?.number_ordered;

  function handleClickDelete() {
    globalActions.openConfirmModal(() => {
      addToast(t("wird bearbeitet ..."), { appearance: "info" });
      globalActions.deleteGroupOrder(school_code);
      globalActions.closeConfirmModal();
    });
  }

  function handleClickDuplicate() {
    addToast(t("wird bearbeitet ..."), { appearance: "info" });
    globalActions.duplicateGroupOrder(school_code);
    window.scrollTo(0, 0);
    sendPageToGA('/calcuso-group-order-teacher-duplicate', 'GO Teacher | Duplicate group order')
  }

  return (
    <Transition
      show={open}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        static
        className="absolute right-0 z-10 w-56 mt-2 origin-top-right border divide-y divide-gray-100 rounded-md shadow-lg outline-none border-cal-primary-celadon-green-01A7A3 bg-cal-primary-celadon-green-01A7A3"
      >
        <div className="py-1">
          <Menu.Item>
            <a
              href={`#/group-orders/${school_code}`}
              className="flex items-center px-4 py-2 text-sm outline-none text-cal-primary-floral-white-F5F0E1 group hover:bg-cal-primary-celadon-green-008E8D hover:text-cal-primary-floral-white-FEF9EF"
              role="menuitem"
            >
              <EditIcon className="w-5 h-5 mr-3 text-cal-primary-floral-white-F5F0E1 hover:text-cal-primary-floral-white-FEF9EF" />
              <span>{t("bearbeiten")}</span>
            </a>
          </Menu.Item>
        </div>

        <div className="py-1">
          <Menu.Item>
            <a
                href={`#/group-orders/${school_code}/overview`}
                className="flex items-center px-4 py-2 text-sm outline-none text-cal-primary-floral-white-F5F0E1 group hover:bg-cal-primary-celadon-green-008E8D hover:text-cal-primary-floral-white-FEF9EF"
                role="menuitem"
            >
              <BasketIcon
                  className="w-5 h-5 mr-3 text-cal-primary-floral-white-F5F0E1 hover:text-cal-primary-floral-white-FEF9EF"/>
              <span>{t("Orders/Status")}</span>
            </a>
          </Menu.Item>
        </div>

        {status === ACTIVE || status === EXPIRED ? (
          <div className="py-1">
            <Menu.Item>
              <a
                href={print_url}
                rel="noreferrer"
                target="_blank"
                className="flex items-center w-full px-4 py-2 text-sm outline-none cursor-pointer text-cal-primary-floral-white-F5F0E1 group hover:bg-cal-primary-celadon-green-008E8D hover:text-cal-primary-floral-white-FEF9EF"
                role="menuitem"
              >
                <PrintIcon className="w-5 h-5 mr-3 text-cal-primary-floral-white-F5F0E1 hover:text-cal-primary-floral-white-FEF9EF" />
                <span>{t("drucken")}</span>
              </a>
            </Menu.Item>
          </div>
        ) : (
          ""
        )}

        <div className="py-1">
          <Menu.Item>
            <p
              onClick={handleClickDuplicate}
              className="flex items-center w-full px-4 py-2 text-sm outline-none cursor-pointer text-cal-primary-floral-white-F5F0E1 group hover:bg-cal-primary-celadon-green-008E8D hover:text-cal-primary-floral-white-FEF9EF"
              role="menuitem"
            >
              <CopyIcon className="w-5 h-5 mr-3 text-cal-primary-floral-white-F5F0E1 hover:text-cal-primary-floral-white-FEF9EF" />
              <span>{t("Duplicate")}</span>
            </p>
          </Menu.Item>
        </div>
        {number_ordered === 0 ? (
          <div className="py-1">
            <Menu.Item>
              <p
                onClick={handleClickDelete}
                className="flex items-center w-full px-4 py-2 text-sm outline-none cursor-pointer text-cal-primary-floral-white-F5F0E1 group hover:bg-cal-primary-celadon-green-008E8D hover:text-cal-primary-floral-white-FEF9EF"
                role="menuitem"
              >
                <DeleteIcon className="w-5 h-5 mr-3 text-cal-primary-floral-white-F5F0E1 hover:text-cal-primary-floral-white-FEF9EF" />
                <span>{t("löschen")}</span>
              </p>
            </Menu.Item>
          </div>
        ) : (
          ""
        )}
      </Menu.Items>
    </Transition>
  );
}
