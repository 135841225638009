import React from "react";
import { Menu } from "@headlessui/react";
import * as dayjs from "dayjs";
import GroupOrderMenus from "../../components/GroupOrderMenus";
import ImagesOrderItem from "./ImagesOrderItem";
import MenuIcon from "../../components/Icons/MenuIcon";
import Status from "../../components/Status";
import { Link } from "react-router-dom";
import { IN_REVIEW } from "../../constants/status";

export default function ListGroupOrderItem({ order }) {
  const {
    name,
    school_code,
    product_images,
    deadline,
    status,
    number_ordered,
    in_review,
  } = order;

  return (
    <tr>
      <td className="w-full py-4 text-sm font-medium truncate text-cal-primary-celadon-green-00B5B1 hover:text-cal-primary-celadon-green-008E8D max-w-0 whitespace-nowrap">
        <Link to={`/group-orders/${school_code}`}>{name}</Link>
      </td>
      <td className="px-6 py-4 text-sm text-cal-primary-gunmetal-black-202833 md:table-cell whitespace-nowrap">
        {school_code}
      </td>
      <td className="px-6 py-4 text-sm text-cal-primary-gunmetal-black-202833 md:table-cell whitespace-nowrap">
        {deadline ? dayjs(deadline).format("DD.MM.YYYY") : ""}
      </td>
      <td className="px-6 py-4 text-sm uppercase text-cal-primary-gunmetal-black-202833 md:table-cell whitespace-nowrap">
        <Link to={`/group-orders/${school_code}/status`}>
          {in_review ? <Status>{IN_REVIEW}</Status> : <Status>{status}</Status>}
        </Link>
      </td>
      <td className="hidden px-6 py-4 text-sm font-medium text-center lg:table-cell text-cal-primary-celadon-green-00B5B1 hover:text-cal-primary-celadon-green-008E8D whitespace-nowrap">
        <Link to={`/group-orders/${school_code}/overview`}>
            {number_ordered < 0 ? '-' : number_ordered}
        </Link>
      </td>
      <td className="hidden px-6 py-4 text-sm text-gray-500 lg:table-cell ">
        <div className="flex items-center space-x-2">
          <ImagesOrderItem images={product_images} />
        </div>
      </td>
      <td>
        <div className="relative flex items-center justify-end">
          <Menu>
            {({ open }) => (
              <>
                <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800">
                  <MenuIcon className="w-6 h-6 text-cal-primary-celadon-green-00B5B1" />
                </Menu.Button>

                <GroupOrderMenus open={open} order={order} />
              </>
            )}
          </Menu>
        </div>
      </td>
    </tr>
  );
}
