import React from "react";
import PaginationNext from "./PaginationNext";
import PaginationItem from "./PaginationItem";
import PaginationPrevious from "./PaginationPrevious";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

export default function Pagination({
                                     totalRecords = null,
                                     pageLimit = 30,
                                     pageNeighbours = 0,
                                     currentPage,
                                     onPageChanged,
                                     scrollTop
                                   }) {
  pageLimit = typeof pageLimit === "number" ? pageLimit : 30;
  totalRecords = typeof totalRecords === "number" ? totalRecords : 0;

  pageNeighbours =
    typeof pageNeighbours === "number"
      ? Math.max(0, Math.min(pageNeighbours, 2))
      : 0;

  const totalPages = Math.ceil(totalRecords / pageLimit);

  const gotoPage = (page) => {
    const currentPage = Math.max(0, Math.min(page, totalPages));

    const paginationData = {
      currentPage,
      totalPages: totalPages,
      pageLimit: pageLimit,
      totalRecords: totalRecords,
    };

    onPageChanged(paginationData);
    afterClick();
  };

  const handleClick = (page, evt) => {
    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    if (currentPage > 1) {
      gotoPage(currentPage - 1);
    }
  };

  const handleMoveRight = (evt) => {
    evt.preventDefault();
    if (currentPage < totalPages) {
      gotoPage(currentPage + 1);
    }
  };

  const afterClick = () => {
    if (scrollTop === true) {
      window.scrollTo(0, 0);
    }
  };

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  if (!totalRecords) return null;

  if (totalPages === 1) return null;

  const pages = fetchPageNumbers();

  return (
    <nav className="flex items-center justify-between px-4 border-t border-gray-200 sm:px-0">
      {currentPage > 1 ? (
        <div onClick={handleMoveLeft} className="flex flex-1 w-0 -mt-px">
          <PaginationPrevious />
        </div>
      ) : (
        <div className="flex flex-1 w-0 -mt-px"></div>
      )}

      <div className="hidden md:-mt-px md:flex">
        {pages.map((page, index) => {
          if (page === LEFT_PAGE)
            return (
              <span
                key={index}
                className="inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500 border-t-2 border-transparent"
              >
                ...
              </span>
            );

          if (page === RIGHT_PAGE)
            return (
              <span
                key={index}
                className="inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500 border-t-2 border-transparent"
              >
                ...
              </span>
            );

          return (
            <PaginationItem
              key={index}
              active={currentPage === page}
              onClick={(e) => handleClick(page, e)}
            >
              {page}
            </PaginationItem>
          );
        })}
      </div>

      {currentPage < totalPages ? (
        <div
          onClick={handleMoveRight}
          className="flex justify-end flex-1 w-0 -mt-px"
        >
          <PaginationNext />
        </div>
      ) : (
        <div className="flex justify-end flex-1 w-0 -mt-px"></div>
      )}
    </nav>
  );
}
