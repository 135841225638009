import * as React from "react";

function CopyIcon(props) {
  return (
    <svg
      data-name="Icon Duplicate 16"
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path data-name="48 x 48" fill="none" d="M0 0h16v16H0z" />
      <g
        data-name="Group 663"
        fill="none"
        stroke="#f5f0e1"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          data-name="Path 252"
          d="M10.86 15.387H2.732a.762.762 0 01-.762-.762V4.973a.762.762 0 01.762-.762h8.128a.762.762 0 01.762.762v9.652a.762.762 0 01-.762.762z"
        />
        <path
          data-name="Path 253"
          d="M4.586 1.762h8.593a.806.806 0 01.806.806v10.2"
        />
      </g>
    </svg>
  );
}

export default CopyIcon;
