import classNames from "classnames";
import React from "react";
import { ACTIVE, EXPIRED, HOLD, IN_REVIEW } from "../constants/status";
import { useTranslation } from "react-i18next";

export default function Status({ children }) {
  const { t } = useTranslation();
  return (
    <span
      className={classNames(" font-medium  ", {
        " text-cal-primary-celadon-green-01A7A3": children === ACTIVE,
        " text-cal-secondary-fiery-rose-f74e6c": children === EXPIRED,
        " text-cal-primary-gunmetal-black-989898": children === HOLD,
        " text-yellow-500": children === IN_REVIEW,
      })}
    >
      {t(children)}
    </span>
  );
}
