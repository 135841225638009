import * as React from "react";

function UserIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 28 28">
      <g id="Icon_User" data-name="Icon User" transform="translate(1 1)">
        <path
          id="Path_212"
          data-name="Path 212"
          d="M1264.789,291.937a11.818,11.818,0,0,1,11.818-11.818h2.364a11.818,11.818,0,0,1,11.818,11.818"
          transform="translate(-1264.789 -265.937)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_61"
          data-name="Ellipse 61"
          cx="7"
          cy="7"
          r="7"
          transform="translate(6)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default UserIcon;
