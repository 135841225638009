import React, {Component} from "react";
import Step from "./Step";
import Button from "../../components/Forms/Button";
import PropTypes from "prop-types";
import history from "../../routes/history";
import {ACTIVE, EXPIRED, NOT_ACTIVE, PRODUCTS, SETTINGS, SHARE,} from "../../constants";
import {connect} from "../../state/useGlobal";
import StepMobile from "./StepMobile";
import {withTranslation} from "react-i18next";
import classNames from "classnames";
import {sendPageToGA} from "../../services/ga4";

class Steps extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Array).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            activeTab: PRODUCTS,
            loadingNext: false,
        };
    }

    componentDidMount() {
        const {globalState} = this.props;
        const {groupOrder} = globalState;
        const {status} = groupOrder;

        if (status === ACTIVE || status === EXPIRED) {
            this.setState({
                activeTab: SHARE,
            });
            sendPageToGA('/calcuso-group-order-teacher-create-done', 'GO Teacher | Step 4 | Done share screen');
        } else {
            this.setState({
                activeTab: PRODUCTS,
            });
            sendPageToGA('/calcuso-group-order-teacher-select-product', 'GO Teacher | Step 2 | Select Products');
        }
    }

    handleOnClickTabItem = (tab) => {
        switch (tab) {
            case PRODUCTS:
                sendPageToGA('/calcuso-group-order-teacher-select-product', 'GO Teacher | Step 2 | Select Products');
                break;
            case SETTINGS:
                sendPageToGA('/calcuso-group-order-teacher-setting', 'GO Teacher | Step 3 | Setting');
                break;
        }
        this.setState({activeTab: tab});
        window.scrollTo(0, 0);
    };

    handleClickNext = async () => {
        this.setState({loadingNext: true});
        const {activeTab} = this.state;
        const {onSubmitGroupOrder} = this.props;

        switch (activeTab) {
            case PRODUCTS:
                this.setState({activeTab: SETTINGS});
                window.scrollTo(0, 0);
                sendPageToGA('/calcuso-group-order-teacher-setting', 'GO Teacher | Step 3 | Setting');
                break;
            case SETTINGS:
                const success = await onSubmitGroupOrder();
                if (success) {
                    const {globalState} = this.props;
                    const {groupOrder} = globalState;
                    const {status} = groupOrder;
                    if (status === ACTIVE || status === EXPIRED) {
                        this.setState({activeTab: SHARE});
                    } else if (status === NOT_ACTIVE) {
                        this.setState({activeTab: PRODUCTS});
                    }
                }
                window.scrollTo(0, 0);
                sendPageToGA('/calcuso-group-order-teacher-create-done', 'GO Teacher | Step 4 | Done share screen');
                break;
            default:
                history.push("/");
        }

        this.setState({loadingNext: false});
    };

    render() {
        const {
            handleClickNext,
            handleOnClickTabItem,
            props: {children, t},
            state: {activeTab, loadingNext},
        } = this;
        const {globalState} = this.props;
        const {groupOrder, submitGroupOrder} = globalState;
        const {status} = groupOrder;
        const {isValid} = submitGroupOrder;

        return (
            <>
                <div className="hidden md:items-center md:justify-between md:flex">
                    <div className="w-full ">
                        <div
                            className="border-2 rounded-md border-r-3 text-cal-primary-celadon-green-01A7A3 border-cal-primary-celadon-green-00B5B1 bg-cal-primary-floral-white-FEF9EF">
                            <ol className="md:flex">
                                {children.map((child, key) => {
                                    const {label} = child.props;
                                    let disabled = true;

                                    switch (label) {
                                        case SHARE:
                                            if (status === ACTIVE || status === EXPIRED) {
                                                disabled = false;
                                            }
                                            break;

                                        default:
                                            disabled = false;
                                            break;
                                    }

                                    return (
                                        <Step
                                            first={key === 0}
                                            active={activeTab === label}
                                            key={label}
                                            label={label}
                                            disabled={disabled}
                                            onClick={handleOnClickTabItem}
                                        />
                                    );
                                })}
                            </ol>
                        </div>
                    </div>

                    {activeTab !== SHARE ? (
                        <div className="pl-4 ">
                            <Button
                                size="xl"
                                styleType="primary"
                                type="button"
                                onClick={handleClickNext}
                                loading={loadingNext}
                                disabled={activeTab === SETTINGS && !isValid}
                            >
                                <span className="text-sm md:text-base">{t("weiter")}</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-5 h-5 ml-3 -mr-1"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </Button>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="md:hidden">
                    <nav className="flex justify-center w-full">
                        <ol className="flex items-center justify-between space-x-4">
                            {children.map((child, key) => {
                                const {label} = child.props;

                                return (
                                    <StepMobile
                                        first={key === 0}
                                        active={activeTab === label}
                                        key={label}
                                        label={label}
                                        onClick={handleOnClickTabItem}
                                    />
                                );
                            })}
                        </ol>
                    </nav>
                    {activeTab !== SHARE ? (
                        <div className="fixed inset-x-0 bottom-0 z-50 ">
                            <div className="h-16 bg-cal-primary-floral-white-FEF9EF">
                                <div className="flex flex-col items-center justify-center h-full px-6 py-3 mx-auto">
                                    <div className="flex justify-end w-full ">
                                        <div className="w-1/2">
                                            <Button
                                                size="xl"
                                                styleType="primary"
                                                type="button"
                                                onClick={handleClickNext}
                                                loading={loadingNext}
                                                disabled={activeTab === SETTINGS && !isValid}
                                            >
                        <span className="text-sm md:text-base">
                          {t("weiter")}
                        </span>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="w-5 h-5 ml-3 -mr-1"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <div>
                    {children.map((child) => {
                        return (
                            <div
                                key={child.props.label}
                                className={classNames("", {
                                    hidden: child.props.label !== activeTab,
                                })}
                            >
                                {child.props.children}
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }
}

export default connect(withTranslation()(Steps));
