import api from "../services/api";

export const initialState = {};

export const setGroupOrders = (store, groupOrders) => {
  store.setState({ groupOrders });
};

export const loadGroupOrders = async (store) => {
  // Load async data.
  let groupOrdersData = await api.get("/group-orders");

  const { data } = groupOrdersData;
  const { success, payload } = data;
  if (success) {
    const { orders } = payload;
    store.setState({ groupOrders: orders });
  }
};

export const deleteGroupOrder = async (store, schoolCode) => {
  // Load async data.
  let groupOrdersData = await api.del(`/group-orders/${schoolCode}`);

  const { data } = groupOrdersData;
  const { success } = data;
  if (success) {
    const groupOrders = store.state.groupOrders.filter(
      (groupOrder, j) => groupOrder.school_code !== schoolCode
    );
    store.setState({ groupOrders });
  }
};

export const duplicateGroupOrder = async (store, schoolCode) => {
  // Load async data.
  let groupOrdersData = await api.post(`/group-orders/${schoolCode}/duplicate`);

  const { data } = groupOrdersData;
  const { success } = data;
  if (success) {
    await loadGroupOrders(store);
  }
};
