import classNames from "classnames";
import React, { Component } from "react";

export default class PaginationNext extends Component {
  render() {
    const { children, active, onClick } = this.props;
    return (
      <div
        onClick={onClick}
        className={classNames(
          "inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500 border-t-2 border-transparent cursor-pointer hover:text-gray-700 hover:border-gray-300",
          {
            "border-cal-primary-celadon-green-008E8D text-cal-primary-celadon-green-008E8D": active,
            "text-gray-500 border-transparent": !active,
          }
        )}
      >
        {children}
      </div>
    );
  }
}
