import React, { Component } from "react";

export default class ProductImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errored: false,
    };
  }

  handleError = () => {
    this.setState({ errored: true });
  };

  render() {
    if (this.state.errored) {
      return (
        <svg
          className="w-full h-32 mx-auto text-gray-300 bg-white border border-gray-300"
          preserveAspectRatio="none"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 200 200"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeWidth="1"
            d="M0 0l200 200M0 200L200 0"
          ></path>
        </svg>
      );
    } else {
      return (
        <img
          className="flex-shrink-0 object-contain h-32 mx-auto"
          src={this.props.src}
          alt={this.props.alt}
          onError={this.handleError}
        />
      );
    }
  }
}
