import React, { Component } from "react";
import Logo from "../components/Logo";
import GuestLayout from "../components/Layouts/GuestLayout";
import Config from "../config";
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Button from "../components/Button";
import api from "../services/api";
import { withToastManager } from "react-toast-notifications";
import history from "../routes/history";
import { connect } from "../state/useGlobal";
import { withTranslation } from "react-i18next";
import CountrySwitcher from "../components/CountrySwitcher";
import {sendPageToGA} from "../services/ga4";

class LoginScreen extends Component {
  constructor(props) {
    super(props);
    const { globalState } = this.props;

    this.state = {
      isLoading: false,
      ssoURL: Config.stores[globalState.store] + Config.ssoURL,
    };
  }

  handleOnChange = (e) => {
    this.setState({ ssoURL: Config.stores[e] + Config.ssoURL });
  };
  async componentDidMount() {
    sendPageToGA('/calcuso-group-order-teacher-login', 'GO Teacher | Login');
  }

  render() {
    const { t } = this.props;
    const { ssoURL } = this.state;
    const isAuthenticated =
      localStorage.getItem("token") && localStorage.getItem("user");
    if (isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }

    return (
      <GuestLayout>
        <div className="flex flex-col justify-center min-h-screen py-12 bg-gray-100 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <Logo className="w-auto h-8 mx-auto text-teal-400" />
            <h2 className="mt-6 text-2xl font-extrabold text-center text-cal-primary-gunmetal-black-202833">
              {t("Kunden - Login")}
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="mx-4 mb-6 md:mx-0">
              <CountrySwitcher onChange={this.handleOnChange} />
            </div>

            <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
              <Formik
                validateOnMount
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email(t("Ungültige E-Mail-Adresse"))
                    .required(t("Pflichtfeld")),
                  password: Yup.string().required(t("Pflichtfeld")),
                })}
                onSubmit={async (values) => {
                  this.setState({ isLoading: true });
                  const { toastManager, globalActions } = this.props;

                  try {
                    let loginData = await api.post(
                      "/group-orders/login",
                      {},
                      values
                    );
                    const { data } = loginData;
                    const { success, payload } = data;

                    if (success) {
                      const { access_token } = payload;

                      localStorage.setItem("token", access_token);

                      const user = await api.me(access_token);
                      globalActions.setUser(user);

                      history.push("/");
                    }
                  } catch (error) {
                    const { data } = error;
                    const { message } = data;
                    toastManager.add(message, {
                      appearance: "error",
                      autoDismiss: true,
                    });
                  }

                  this.setState({ isLoading: false });
                }}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur,
                  isValid,
                }) => (
                  <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("E-Mail")}
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                      </div>
                      {touched.email && errors.email ? (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="email-error"
                        >
                          {errors.email}
                        </p>
                      ) : null}
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("Passwort")}
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                      </div>
                      {touched.password && errors.password ? (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="password-error"
                        >
                          {errors.password}
                        </p>
                      ) : null}
                    </div>

                    {/* <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember_me"
                      name="remember_me"
                      type="checkbox"
                      className="w-4 h-4 text-teal-600 border-gray-300 rounded focus:ring-teal-500"
                    />
                    <label
                      htmlFor="remember_me"
                      className="block ml-2 text-sm text-cal-primary-gunmetal-black-202833"
                    >
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm">
                    <a
                      href="/"
                      className="font-medium text-teal-600 hover:text-teal-500"
                    >
                      Forgot your password?
                    </a>
                  </div>
                </div> */}

                    <div>
                      <Button
                        color="primary"
                        rounded="md"
                        type="submit"
                        disabled={!isValid}
                        width="full"
                        loading={this.state.isLoading}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5 mr-3 -ml-1"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>{t("Anmelden")}</span>
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 text-gray-500 bg-white">
                      {t("Oder fahren Sie fort mit")}
                    </span>
                  </div>
                </div>

                <div className="w-full my-4">
                  <a
                    href={ssoURL}
                    className="inline-flex items-center justify-center w-full px-4 py-2 text-xs font-medium tracking-widest text-white uppercase transition duration-150 duration-500 ease-in-out transform bg-orange-600 border border-transparent rounded-md hover:bg-orange-700 active:bg-orange-900 focus:outline-none focus:border-orange-900 focus:ring ring-gray-300 disabled:opacity-25 hover:translate-2 hover:scale-110"
                  >
                    <svg
                      className="h-5 mr-2 left"
                      viewBox="0 0 256 303"
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="xMidYMid"
                    >
                      <path
                        d="M144.852 90.67v172.192l-16.933 10.349-16.946-10.404V90.803l-43.878 27.016v147.255l60.824 37.217 61.339-37.482V117.725L144.852 90.67zM127.919 0L0 77.502v147.274l33.223 19.572V97.06l94.722-57.596 94.81 57.512.391.223-.042 146.929L256 224.776V77.502L127.919 0z"
                        fill="#fff"
                      />
                    </svg>{" "}
                    <span>{t("Continue with Calcuso account")}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </GuestLayout>
    );
  }
}

export default withToastManager(connect(withTranslation()(LoginScreen)));
