import React, { Component } from "react";
import CategoryItem from "./CategoryItem";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import api from "../../services/api";
import { connect } from "../../state/useGlobal";

const LoadingCategories = () => (
  <div className="w-full pt-4">
    <div className="flex space-x-4 animate-pulse">
      <div className="flex-1 py-1 space-y-2">
        <div className="h-4 rounded bg-cal-primary-floral-white-EFE9D6"></div>
        <div className="h-4 rounded bg-cal-primary-floral-white-EFE9D6"></div>
      </div>
    </div>
  </div>
);

class index extends Component {
  static propTypes = {
    onFilter: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      categories: [],
      selected: "",
    };
  }

  handleClick = (selected) => {
    this.setState({ selected });
    this.props.onFilter(selected);
  };

  handleClickRemove = (key) => {
    this.setState({ selected: "" });
    this.props.onFilter(false);
  };

  async componentDidMount() {
    const { globalActions } = this.props;
    // Load async data.
    let connfigData = await api.get(`/group-orders/config`);

    const { data } = connfigData;
    const { success, payload } = data;
    if (success) {
      const { product_filters } = payload;
      globalActions.setConfig(payload);
      this.setState({
        isLoading: false,
        categories: Object.values(product_filters).sort((a, b) =>
          a.position > b.position ? 1 : -1
        ),
      });
    }
  }

  render() {
    const { categories, selected, isLoading } = this.state;
    return (
      <>
        {isLoading ? (
          <LoadingCategories />
        ) : (
          <div className="mt-2 space-x-2 space-y-2 flex-wrap items-center">
            {categories.map((category) => (
              <CategoryItem
                key={category.key}
                id={category.key}
                onClick={this.handleClick}
                onClickRemmove={this.handleClickRemove}
                active={selected === category.key}
              >
                {category.text_display}
              </CategoryItem>
            ))}
          </div>
        )}
      </>
    );
  }
}

export default connect(withTranslation()(index));
