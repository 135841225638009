import React, { Component } from "react";
import Loading from "../HomeScreen/Loading";
import { withRouter } from "react-router";
import { connect } from "../../state/useGlobal";
import api from "../../services/api";
import { withToastManager } from "react-toast-notifications";
import { withTranslation } from "react-i18next";
import * as dayjs from "dayjs";
import { CheckIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import {sendPageToGA} from "../../services/ga4";

const getStepByName = (obj, name) =>
  Object.values(obj).find((value) => value.name === name);

class OrderStatusScreen extends Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;

    this.state = {
      isLoading: true,
      schoolCode: id,
      shipments: [],
      process: false,
    };
  }

  async componentDidMount() {
    const { schoolCode } = this.state;
    // Load async data.
    let statusData = await api.get(`/group-orders/${schoolCode}/status`);

    const { data } = statusData;

    const { success, payload } = data;
    if (success) {
      const { shipments, process } = payload;
      this.setState({
        shipments,
        process,
        isLoading: false,
      });
    }

    sendPageToGA('/calcuso-group-order-teacher-order-status', 'GO Teacher | Order status')
  }

  render() {
    const { isLoading, shipments } = this.state;
    let { process } = this.state;
    const { t } = this.props;

    if (process instanceof Array && process.length < 1){
        process = null;
    }

    const readyObject = getStepByName(process ? process.steps : {}, "ready");
    const productionObject = getStepByName(
      process ? process.steps : {},
      "production"
    );
    const putwallObject = getStepByName(
      process ? process.steps : {},
      "putwall"
    );
    const shipoutObject = getStepByName(
      process ? process.steps : {},
      "shipout"
    );

    const arraySteps = [
      {
        id: "ready",
        name: t("Ein Teil der Sammelbestellung wird produziert (Gravur)"),
        description: readyObject
          ? readyObject.started_at
            ? dayjs(readyObject.started_at).format("DD.MM.YYYY")
            : ""
          : "",
        status: process?.step === 'ready' ? 'current' : (readyObject && readyObject.started_at ? "complete" : "upcoming"),
      },
      {
        id: "production",
        name: t("Produktion fertig"),
        description: productionObject
          ? productionObject.started_at
            ? dayjs(productionObject.started_at).format("DD.MM.YYYY")
            : ""
          : "",
        status: process?.step === 'production' ? 'current' : (productionObject && productionObject.started_at ? "complete" : "upcoming"),
      },
      {
        id: "putwall",
        name: t("Die Sammelbestellung wurde verpackt"),
        description: putwallObject
          ? putwallObject.started_at
            ? dayjs(putwallObject.started_at).format("DD.MM.YYYY")
            : ""
          : "",
        status: (process?.step === 'putwall' || process?.step === 'pickslist') ? 'current' : (putwallObject && putwallObject.started_at ? "complete" : "upcoming"),
      },
      {
        id: "shipout",
        name: t(
          "Die Sammelbestellung wird in {{ countShipments }} Sendungen versendet",
          { countShipments: shipments.length }
        ),
        description: (
          <>
            {shipoutObject
              ? shipoutObject.started_at
                ? dayjs(shipoutObject.started_at).format("DD.MM.YYYY")
                : ""
              : ""}
            <div className="flex flex-col py-4 space-y-4 ">
              {shipments.map((tracking, trackingIdx) => (
                <p
                  key={tracking.shipcloud_carrier_tracking_no}
                  className="text-base text-cal-primary-gunmetal-black-202833"
                >
                  {t('Trackingnummer')}:{" "}
                  <a
                    href={tracking.shipcloud_tracking_url}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-flex items-center px-2.5 py-0.5 rounded-md bg-cal-primary-celadon-green-E5F8F7 text-cal-primary-gunmetal-black-202833"
                  >
                    {tracking.shipcloud_carrier_tracking_no}
                  </a>
                </p>
              ))}
            </div>
          </>
        ),
        status: process?.step === 'shipout' ? 'current' : (shipoutObject && shipoutObject.started_at ? "complete" : "upcoming"),
      },
    ];

    return (
        <div className="h-full px-2 mt-5 mb-16 md:pb-5 ">
          <div className="flex flex-col mt-4 mb-4 md:mt-20">
            <h3 className="text-xl font-medium md:text-2xl text-cal-primary-gunmetal-black-202833">
              {t("Status Bestellung")}
            </h3>
          </div>

          {isLoading ? (
            <Loading />
          ) : (
            <>
              <nav aria-label="Progress" className="py-8">
                <ol className="overflow-hidden">
                  {arraySteps.map((step, stepIdx) => (
                    <li
                      key={step.name}
                      className={classNames("relative", {
                        "pb-10": stepIdx !== arraySteps.length - 1,
                      })}
                    >
                      {step.status === "complete" ? (
                        <>
                          {stepIdx !== arraySteps.length - 1 ? (
                            <div
                              className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-cal-primary-celadon-green-00B5B1"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div className="relative flex items-start group">
                            <span className="flex items-center h-9">
                              <span className="relative z-10 flex items-center justify-center w-8 h-8 rounded-full bg-cal-primary-celadon-green-00B5B1 group-hover:bg-cal-primary-celadon-green-008E8D">
                                <CheckIcon
                                  className="w-5 h-5 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            </span>
                            <span className="flex flex-col min-w-0 ml-4">
                              <span className="tracking-wide ">
                                {step.name}
                              </span>
                              <span className="text-sm text-gray-500">
                                {step.description}
                              </span>
                            </span>
                          </div>
                        </>
                      ) : step.status === "current" ? (
                        <>
                          {stepIdx !== arraySteps.length - 1 ? (
                            <div
                              className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div
                            className="relative flex items-start group"
                            aria-current="step"
                          >
                            <span
                              className="flex items-center h-9"
                              aria-hidden="true"
                            >
                              <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-white border-2 rounded-full border-cal-primary-celadon-green-00B5B1">
                                <span className="h-2.5 w-2.5 bg-cal-primary-celadon-green-00B5B1 rounded-full" />
                              </span>
                            </span>
                            <span className="flex flex-col min-w-0 ml-4">
                              <span className="tracking-wide text-cal-primary-celadon-green-00B5B1">
                                {step.name}
                              </span>
                              <span className="text-sm text-gray-500">
                                {step.description}
                              </span>
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          {stepIdx !== arraySteps.length - 1 ? (
                            <div
                              className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div className="relative flex items-start group">
                            <span
                              className="flex items-center h-9"
                              aria-hidden="true"
                            >
                              <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                              </span>
                            </span>
                            <span className="flex flex-col min-w-0 ml-4">
                              <span className="tracking-wide text-cal-primary-gunmetal-black-202833">
                                {step.name}
                              </span>
                              <span className="text-sm text-gray-500">
                                {step.description}
                              </span>
                            </span>
                          </div>
                        </>
                      )}
                    </li>
                  ))}
                </ol>
              </nav>
            </>
          )}
        </div>
    );
  }
}

export default withToastManager(
  withRouter(connect(withTranslation()(OrderStatusScreen)))
);
