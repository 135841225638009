import * as React from "react";

function PrintIcon(props) {
  return (
    <svg
      data-name="Icon Print 16"
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path data-name="48 x 48" fill="none" d="M0 0h16v16H0z" />
      <g
        data-name="Group 660"
        transform="translate(-35.939 -1699.189)"
        fill="none"
        stroke="#f5f0e1"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          data-name="Path 250"
          d="M40.076 1711.72h-3.087v-8.026h14.2v8.026H48.41"
        />
        <path
          data-name="Rectangle 503"
          d="M40.314 1710.08h8.073v3.799h-8.073z"
        />
        <path data-name="Path 251" d="M39.15 1703.695v-1.852h9.878v1.852" />
        <circle
          data-name="Ellipse 70"
          cx={0.237}
          cy={0.237}
          r={0.237}
          transform="translate(38.414 1705.806)"
        />
        <path data-name="Line 150" d="M47.437 1705.331h2.374" />
        <path data-name="Line 151" d="M47.437 1706.756h2.374" />
        <circle
          data-name="Ellipse 71"
          cx={0.237}
          cy={0.237}
          r={0.237}
          transform="translate(40.314 1705.806)"
        />
      </g>
    </svg>
  );
}

export default PrintIcon;
