import React, { Component } from "react";
import StepsModalLayout from "../../components/Layouts/StepsModalLayout";
import Steps from "../../components/Steps";
import { withRouter } from "react-router";
import Loading from "../HomeScreen/Loading";
import { connect } from "../../state/useGlobal";
import ProductsStep from "./Steps/ProductsStep";
import SettingsStep from "./Steps/SettingsStep";
import ShareStep from "./Steps/ShareStep";
import { withTranslation } from "react-i18next";

class GroupOrderScreen extends Component {
  submitGroupOrder = null;

  constructor(props) {
    super(props);
    const id = this.props.match.params.id;

    this.state = {
      isLoading: true,
      schoolCode: id,
    };
  }

  componentDidMount() {
    this._loadGroupOrder();
  }

  _loadGroupOrder = async () => {
    const { globalActions } = this.props;
    const { schoolCode } = this.state;
    globalActions.loadGroupOrder(schoolCode, () =>
      this.setState({
        isLoading: false,
      })
    );
  };

  bindSubmitGroupOrder = (submitForm) => {
    this.submitGroupOrder = submitForm;
  };

  render() {
    const { schoolCode, isLoading } = this.state;
    const { t } = this.props;

    return (
      <StepsModalLayout schoolCode={schoolCode}>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="h-full px-2 py-5">
            <div className="hidden ">
              {t("Produkte")}
              {t("Einstellungen")}
              {t("Teilen")}
            </div>
            <Steps onSubmitGroupOrder={() => this.submitGroupOrder()}>
              <div label="Produkte">
                <ProductsStep schoolCode={schoolCode} />
              </div>
              <div label="Einstellungen">
                <SettingsStep bindSubmitForm={this.bindSubmitGroupOrder} />
              </div>
              <div label="Teilen">
                <ShareStep schoolCode={schoolCode} />
              </div>
            </Steps>
          </div>
        )}
      </StepsModalLayout>
    );
  }
}

export default withRouter(connect(withTranslation()(GroupOrderScreen)));
