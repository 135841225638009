import React from "react";
import Tabs from "../../../components/Tabs";
import AllProductsTab from "../AllProductsTab";
import SelectedProductsTab from "../SelectedProductsTab";
import useGlobal from "../../../state/useGlobal";
import { useTranslation } from "react-i18next";

export default function ProductsStep({ schoolCode }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const { groupOrder } = globalState;
  const { items } = groupOrder;

  return (
    <>
      <div className="pt-4 md:py-8 ">
        <h3 className="text-xl font-bold md:text-2xl ">
          {t("Wähle Produkte aus")}:
        </h3>
      </div>
      <div className="hidden ">
        {t("alle Produkte")}
        {t("ausgewählte")}
      </div>
      <div>
        <Tabs>
          <div label="alle Produkte">
            <AllProductsTab schoolCode={schoolCode} />
          </div>

          <div underline label="ausgewählte" badge={items.length}>
            <SelectedProductsTab schoolCode={schoolCode} />
          </div>
        </Tabs>
      </div>
    </>
  );
}
