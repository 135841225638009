import api from "../services/api";

export const initialState = {};

export const setConfig = (store, config) => {
  store.setState({ config });
};

export const loadConfig = async (store) => {
  // Load async data.
  let connfigData = await api.get(`/group-orders/config`);

  const { data } = connfigData;
  const { success, payload } = data;
  if (success) {
    store.setState({ config: payload });
  }
};
