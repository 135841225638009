import api from "../services/api";

export const initialState = {
  isLock: false,
};

export const setGroupOrder = (store, groupOrder, callback = false) => {
  if (callback) {
    store.setState(
      {
        groupOrder: {
          ...groupOrder,
          isLock: false
        },
      },
      callback
    );
  } else {
    store.setState({
      groupOrder: {
        ...groupOrder,
        isLock: false
      },
    });
  }
};

export const loadGroupOrder = async (store, schoolCode, callback) => {
  // Load async data.
  let groupOrderData = await api.get(`/group-orders/${schoolCode}`);

  const { data } = groupOrderData;
  const { success, payload } = data;

  if (success) {
    store.setState(
      {
        groupOrder: {
          ...payload,
          isLock: false
        },
      },
      callback
    );
  }
};

export const setLockGroupOrder = (store, isLock) => {
  store.setState({
    groupOrder: {
      ...store.state.groupOrder,
      isLock,
    },
  });
};
