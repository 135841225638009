import * as React from "react";

function EditIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12.741}
      height={12.631}
      viewBox="0 0 12.741 12.631"
      {...props}
    >
      <g
        data-name="Group 657"
        fill="none"
        stroke="#f5f0e1"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path data-name="Path 248" d="M6.438 1.703H.5v10.428h10.427V6.482" />
        <path
          data-name="Path 249"
          d="M7.654 6.681l-1.663.073.069-1.663L10.647.504l1.594 1.594z"
        />
      </g>
    </svg>
  );
}

export default EditIcon;
