import {GA4React} from "ga-4-react";
import config from "../config";

export const sendPageToGA = (path, title) => {
    try {
        let ga4 = null;

        if (GA4React.isInitialized()) {
            ga4 = GA4React.getGA4React();
            ga4.pageview(path, path, title);
        } else {
            ga4 = new GA4React(config.gt4, {send_page_view: false});
            ga4.initialize().then((ga4) => {
                ga4.pageview(path, path, title);
            })
        }
    } catch (e) {
        console.log(e)
    }
};
