import React, { Component } from "react";
import PropTypes from "prop-types";
import Tab from "./Tab";
import { connect } from "../../state/useGlobal";

class index extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    const { globalState } = props;
    const { groupOrder } = globalState;
    const { items } = groupOrder;

    this.state = {
      activeTab:
        items.length > 0
          ? this.props.children[1].props.label
          : this.props.children[0].props.label,
    };
  }

  handleOnClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const {
      handleOnClickTabItem,
      props: { children },
      state: { activeTab },
    } = this;

    return (
      <div className="block">
        <nav
          className="flex items-center justify-between py-0 space-x-2 md:justify-start"
          aria-label="Tabs"
        >
          {children.map((child) => {
            const { label, badge, underline } = child.props;

            return (
              <Tab
                active={activeTab === label}
                key={label}
                label={label}
                badge={badge}
                onClick={handleOnClickTabItem}
                underline = {underline}
              />
            );
          })}
        </nav>
        <div className="min-h-screen md:p-4 bg-cal-primary-floral-white-F5F0E1">
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default connect(index);
