import React from "react";
import useGlobal from "../../state/useGlobal";
import ConfirmModal from "../ConfirmModal";
import ProductModal from "../ProductModal";

export default function ModalLayout({ children }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();

  return (
    <>
      <div className="min-h-screen bg-cal-primary-floral-white-F5F0E1 md:bg-cal-primary-celadon-green-008E8D">
        <main>
          <div className="mx-auto md:py-12 max-w-7xl sm:px-6 lg:px-8">
            <div className="p-1 md:p-4 md:px-5 md:py-6 md:rounded-lg md:shadow md:bg-cal-primary-floral-white-FEFCF6">
              {children}
            </div>
          </div>
        </main>
      </div>
      <ProductModal />
      <ConfirmModal />
    </>
  );
}
