import React, { useState, useRef } from "react";
import useGlobal from "../../../state/useGlobal";
import { useToasts } from "react-toast-notifications";
import api from "../../../services/api";
import Button from "../../../components/Button";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import classNames from "classnames";
import {
  BadgeCheckIcon,
  PrinterIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";

export default function ShareStep({ schoolCode }) {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [loadingLetUsPrint, setLoadingLetUsPrint] = useState(false);
  const [copy, setCopy] = useState(false);
  const { groupOrder } = globalState;
  const { school_code, is_requested_let_us_print, url, print_url } = groupOrder;
  const linkShareInputRef = useRef();

  const handleClickPrint = async () => {
    setLoadingPrint(true);

    let printData = await api.post(`/group-orders/${school_code}/print`);

    const { data } = printData;
    const { success, payload, message } = data;
    const { url } = payload;
    setLoadingPrint(false);
    addToast(message, { appearance: success ? "success" : "error" });

    if (success) {
      window.open(url, "_blank");
    }
  };

  const handleClickLetUsPrint = async () => {
    setLoadingLetUsPrint(true);

    let printData = await api.post(`/group-orders/${school_code}/letUsPrint`);

    const { data } = printData;
    const { success, message } = data;
    addToast(message, { appearance: success ? "success" : "error" });

    globalActions.loadGroupOrder(school_code, () => {
      setLoadingPrint(false);
    });
  };

  const handleClickCopy = () => {
    setCopy(true);
    addToast(t("kopiert"), {
      appearance: "info",
    });

    setTimeout(() => {
      setCopy(false);
    }, 1500);

    linkShareInputRef.current.select();
  };

  return (
    <>
      <div className="pt-4 md:py-8 ">
        <h3 className="text-xl font-bold md:text-2xl ">
          {t("Deine Sammelbestellung ist fertig und kann geteilt werden")}:
        </h3>
      </div>
      <div className="py-4 text-center text-cal-secondary-77A6F6 ">
        <h3 className="text-xl font-medium md:text-2xl ">
          {t("Code")}: {schoolCode}
        </h3>
      </div>
      <div className="py-4 text-cal-primary-celadon-green-00B5B1">
        <div className="grid grid-cols-1 gap-4 ">
          <div className="flex items-center px-6 py-8 space-x-8 bg-white border-2 rounded-lg shadow-sm border-cal-primary-celadon-green-00B5B1">
            <div className="flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-10 h-10"
                viewBox="0 0 48 40"
              >
                <g
                  id="Group_658"
                  data-name="Group 658"
                  transform="translate(-35.989 -1700.843)"
                >
                  <path
                    id="Path_250"
                    data-name="Path 250"
                    d="M46.989,1733.843h-10v-26h46v26h-9"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <rect
                    id="Rectangle_503"
                    data-name="Rectangle 503"
                    width="27"
                    height="12"
                    transform="translate(46.989 1727.843)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_251"
                    data-name="Path 251"
                    d="M43.989,1707.843v-6h32v6"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <circle
                    id="Ellipse_70"
                    data-name="Ellipse 70"
                    cx="1"
                    cy="1"
                    r="1"
                    transform="translate(41.989 1714.843)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Line_150"
                    data-name="Line 150"
                    x2="8"
                    transform="translate(70.989 1713.843)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Line_151"
                    data-name="Line 151"
                    x2="8"
                    transform="translate(70.989 1717.843)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <circle
                    id="Ellipse_71"
                    data-name="Ellipse 71"
                    cx="1"
                    cy="1"
                    r="1"
                    transform="translate(47.989 1714.843)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </div>
            <div className="flex flex-col justify-between flex-1 min-w-0 space-y-2 md:items-center md:flex-row">
              <div className="focus:outline-none">
                <p className="text-base font-medium md:text-xl ">
                  {t("Elternbrief selber drucken")}
                </p>
                <p className="text-sm truncate"></p>
              </div>
              <div>
                <a
                  href={print_url}
                  rel="noreferrer"
                  target="_blank"
                  className="inline-flex items-center justify-center px-3 py-2 text-xs font-medium text-center transition duration-500 ease-in-out transform border border-transparent rounded-full shadow-sm md:px-4 md:text-sm bg-cal-primary-celadon-green-00B5B1 hover:bg-cal-primary-celadon-green-01A7A3 focus:outline-none focus:ring-2 focus:ring-offset-2 text-cal-primary-floral-white-FEFCF6 focus:ring-cal-primary-celadon-green-00B5B1 hover:translate-2 hover:scale-110"
                >
                  <PrinterIcon className="w-5 h-5 mr-2 -ml-1" />
                  {t("drucken")}
                </a>
              </div>
            </div>
          </div>

          <div className="relative flex items-center px-6 py-8 space-x-8 bg-white border-2 rounded-lg shadow-sm border-cal-primary-celadon-green-00B5B1">
            <div className="flex-shrink-0">
              <svg
                id="Icon_Save_48"
                data-name="Icon Save 48"
                xmlns="http://www.w3.org/2000/svg"
                className="w-10 h-10 "
                viewBox="0 0 48 48"
              >
                <rect
                  id="_48_x_48"
                  data-name="48 x 48"
                  width="48"
                  height="48"
                  fill="none"
                />
                <g
                  id="Group_749"
                  data-name="Group 749"
                  transform="translate(-136.215 -1369.532)"
                >
                  <path
                    id="Path_304"
                    data-name="Path 304"
                    d="M175.084,1407a14.915,14.915,0,0,0,6.844-7.4"
                    transform="translate(-3.605 -2.29)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_305"
                    data-name="Path 305"
                    d="M151.835,1404.894c-4.573-2.56-7.561-6.8-7.561-11.6,0-5.555,3.991-10.363,9.8-12.689a22.824,22.824,0,0,1,12.761-1.155q.518.105,1.031.234l0,.09s.01.059.014.059"
                    transform="translate(-0.562 -0.258)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_306"
                    data-name="Path 306"
                    d="M176.047,1382.413c2.935,1.97,5.142,4.145,6.167,7.1"
                    transform="translate(-3.7 -0.593)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_307"
                    data-name="Path 307"
                    d="M158.054,1409.87a22.1,22.1,0,0,0,10.344.06"
                    transform="translate(-1.923 -3.305)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_308"
                    data-name="Path 308"
                    d="M157.447,1409.66l-.476,2.673a1.723,1.723,0,0,1-1.993,1.39l-1.657-.3a1.725,1.725,0,0,1-1.39-2l.658-3.688"
                    transform="translate(-1.315 -3.095)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_309"
                    data-name="Path 309"
                    d="M174.536,1407.809l.233,3.535a1.725,1.725,0,0,1-1.6,1.829l-1.681.111a1.723,1.723,0,0,1-1.827-1.6l-.128-1.954"
                    transform="translate(-3.057 -3.101)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_310"
                    data-name="Path 310"
                    d="M182.86,1390.408l1.683.169a1.771,1.771,0,0,1,1.646,1.839L186,1397.08a1.733,1.733,0,0,1-1.785,1.661l-1.628-.222"
                    transform="translate(-4.346 -1.383)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_311"
                    data-name="Path 311"
                    d="M175.5,1381.82c.286-2.55,3.081-4.707.3-5.326-2.5-.558-4.418,1.74-5.325,3.136"
                    transform="translate(-3.149)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_312"
                    data-name="Path 312"
                    d="M143.712,1394.449s-5.38.018-5.117-3.728"
                    transform="translate(0 -1.414)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <circle
                    id="Ellipse_77"
                    data-name="Ellipse 77"
                    cx="1.219"
                    cy="1.219"
                    r="1.219"
                    transform="translate(171.635 1388.324)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_313"
                    data-name="Path 313"
                    d="M155.991,1384.249a19.942,19.942,0,0,1,9.214-1.306"
                    transform="translate(-1.719 -0.638)"
                    fill="none"
                    stroke="#01a7a3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </div>
            <div className="flex flex-col justify-between flex-1 min-w-0 space-y-2 md:items-center md:flex-row">
              <div className="focus:outline-none">
                <p className="text-base font-medium md:text-xl ">
                  {t("Elternbrief bestellen")}
                </p>
                <p className="text-xs md:text-sm">
                  {t("Wir drucken und du sparst Geld")}
                </p>
              </div>
              <div>
                {!is_requested_let_us_print ? (
                  <Button
                    onClick={handleClickLetUsPrint}
                    loading={loadingLetUsPrint}
                    color="primary"
                    size="sm"
                  >
                    <ShoppingCartIcon className="w-5 h-5 mr-2 -ml-1" />
                    {t("auftrag")}
                  </Button>
                ) : (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-cal-primary-floral-white-EFE9D6 text-cal-primary-celadon-green-008E8D">
                    <BadgeCheckIcon className="-ml-1 mr-1.5 h-8 w-8 text-cal-primary-celadon-green-008E8D" />
                    {t("Bereits bestellt")}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="px-6 py-8 bg-white border-2 rounded-lg shadow-sm border-cal-primary-celadon-green-00B5B1">
            <div className="flex items-center space-x-8">
              <div className="flex-shrink-0">
                <svg
                  id="Icon_Share_48"
                  data-name="Icon Share 48"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-10 h-10 "
                  viewBox="0 0 48 48"
                >
                  <rect
                    id="_48_x_48"
                    data-name="48 x 48"
                    width="48"
                    height="48"
                    fill="none"
                  />
                  <g
                    id="Group_726"
                    data-name="Group 726"
                    transform="translate(-792.349 -933.625)"
                  >
                    <path
                      id="Path_289"
                      data-name="Path 289"
                      d="M838.106,965.581v6.182a2.875,2.875,0,0,1-3.028,2.691H796.724a2.874,2.874,0,0,1-3.028-2.691v-28.7a2.874,2.874,0,0,1,3.028-2.691h11.3"
                      fill="none"
                      stroke="#01a7a3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      id="Union_6"
                      data-name="Union 6"
                      d="M826.156,955.741c-4.609.137-8.939,1.648-12.5,6.992,0-10.93,2.985-16.592,12.473-16.7l-.019-7.24,12.89,12-12.828,11.94Z"
                      fill="none"
                      stroke="#01a7a3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              </div>
              <div className="flex-1 min-w-0">
                <div className="focus:outline-none">
                  <p className="text-base font-medium md:text-xl ">
                    {t("Sammelbestellung teilen")}
                  </p>
                </div>
                <div className="hidden mt-4 rounded-md shadow-sm md:flex">
                  <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <input
                      type="text"
                      name="link"
                      id="link"
                      value={url}
                      ref={linkShareInputRef}
                      onChange={() => {}}
                      onClick={(event) => event.target.select()}
                      className="block w-full border-2 rounded-none border-cal-primary-celadon-green-E5F8F7 text-cal-primary-gunmetal-black-202833 rounded-l-md sm:text-sm bg-cal-primary-celadon-green-E5F8F7 focus:outline-none focus:ring-1 focus:ring-cal-primary-celadon-green-E5F8F7 focus:border-cal-primary-celadon-green-E5F8F7"
                    />
                  </div>
                  <CopyToClipboard text={url} onCopy={handleClickCopy}>
                    <button
                      className={classNames(
                        "relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm border-2 text-cal-primary-celadon-green-008E8D border-cal-primary-celadon-green-E5F8F7 rounded-r-md bg-cal-primary-celadon-green-E5F8F7 focus:outline-none focus:ring-1 focus:ring-cal-primary-celadon-green-E5F8F7 focus:border-cal-primary-celadon-green-E5F8F7",
                        {
                          " font-medium": copy,
                        }
                      )}
                    >
                      {copy ? (
                        <span>{t("kopiert")}</span>
                      ) : (
                        <span>{t("kopieren")}</span>
                      )}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            <div className="flex mt-4 rounded-md shadow-sm md:hidden">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <input
                  type="text"
                  name="link-mobile"
                  id="link-mobile"
                  value={url}
                  ref={linkShareInputRef}
                  onChange={() => {}}
                  onClick={(event) => event.target.select()}
                  className="block w-full text-xs border-2 rounded-none border-cal-primary-celadon-green-E5F8F7 text-cal-primary-gunmetal-black-202833 rounded-l-md md:text-sm bg-cal-primary-celadon-green-E5F8F7 focus:outline-none focus:ring-1 focus:ring-cal-primary-celadon-green-E5F8F7 focus:border-cal-primary-celadon-green-E5F8F7"
                />
              </div>
              <CopyToClipboard text={url} onCopy={handleClickCopy}>
                <button
                  className={classNames(
                    "relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-xs md:text-sm border-2 text-cal-primary-celadon-green-008E8D border-cal-primary-celadon-green-E5F8F7 rounded-r-md bg-cal-primary-celadon-green-E5F8F7 focus:outline-none focus:ring-1 focus:ring-cal-primary-celadon-green-E5F8F7 focus:border-cal-primary-celadon-green-E5F8F7",
                    {
                      " font-medium": copy,
                    }
                  )}
                >
                  {copy ? (
                    <span>{t("kopiert")}</span>
                  ) : (
                    <span>{t("kopieren")}</span>
                  )}
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 text-center">
        <Link
          to="/"
          className="inline-flex items-center px-12 py-2 text-xl font-medium transition duration-500 ease-in-out transform border border-transparent rounded-md shadow-sm text-cal-primary-floral-white-FEFCF6 bg-cal-primary-celadon-green-00B5B1 hover:bg-cal-primary-celadon-green-01A7A3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cal-primary-celadon-green-00B5B1 hover:translate-2 hover:scale-110"
        >
          {t("Zurück zur Übersicht")}
        </Link>
      </div>
    </>
  );
}
