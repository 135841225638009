import * as React from "react";

function CalendarIcon(props) {
  return (
    <svg
      data-name="Icon Calendar 32"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      {...props}
    >
      <path data-name="48 x 48" fill="none" d="M0 0h32v32H0z" />
      <g
        data-name="Group 738"
        fill="none"
        stroke="#989898"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path
          data-name="Path 303"
          d="M27.731 29.378H4.205c-1.218 0-2.206-.731-2.206-1.633V7.061c0-.9.987-1.633 2.206-1.633H27.73c1.218 0 2.205.731 2.205 1.633v20.684c.001.902-.983 1.633-2.204 1.633z"
        />
        <g data-name="Group 736">
          <path data-name="Line 187" d="M8.666 8.53V2.175" />
          <path data-name="Line 188" d="M23.27 8.53V2.175" />
        </g>
        <path data-name="Rectangle 604" d="M5.527 11.099h4.544v4.544H5.527z" />
        <path data-name="Rectangle 605" d="M13.8 11.099h4.544v4.544H13.8z" />
        <path
          data-name="Rectangle 606"
          d="M21.866 11.099h4.544v4.544h-4.544z"
        />
        <path data-name="Rectangle 607" d="M5.527 18.983h4.544v4.544H5.527z" />
        <path data-name="Rectangle 608" d="M13.8 18.983h4.544v4.544H13.8z" />
        <path
          data-name="Rectangle 609"
          d="M21.866 18.983h4.544v4.544h-4.544z"
        />
      </g>
    </svg>
  );
}

export default CalendarIcon;
