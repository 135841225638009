import React, { useState } from "react";

export default function ImageOrderItem({ src, alt }) {
  const [errored, setErrored] = useState(false);

  if (errored) {
    return (
      <svg
        className="h-6 text-gray-300 bg-white border border-gray-300 rounded-full"
        preserveAspectRatio="none"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 200 200"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeWidth="1"
          d="M0 0l200 200M0 200L200 0"
        ></path>
      </svg>
    );
  } else {
    return (
      <img
        className="w-6 h-6 rounded-full max-w-none ring-2 ring-white"
        src={src}
        alt={alt}
        onError={setErrored}
      />
    );
  }
}
