import React, { Component } from "react";
import Logo from "../components/Logo";
import GuestLayout from "../components/Layouts/GuestLayout";
import Authentication from "../components/Authentication";
import SimpleLoading from "../components/SimpleLoading";

export default class AuthenticationScreen extends Component {
  render() {
    return (
      <GuestLayout>
        <div className="flex flex-col justify-center min-h-screen py-12 bg-gray-100 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <Logo className="w-auto h-8 mx-auto text-teal-400" />
            <Authentication />
            <SimpleLoading />
          </div>
        </div>
      </GuestLayout>
    );
  }
}
